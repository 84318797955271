import React, { FC } from 'react'

import AnchorLink from '../../AnchorLink'
import Img from '../../Img'

import styles from '../../../styles/Footer.module.less'

interface ImageData {
  link?: string
  src: string
  alternativeText: string
  height?: number | string
  width?: number | string
}
interface FooterLogosProps {
  images: ImageData[]
}

const FooterLogos: FC<FooterLogosProps> = ({ images }) => (
  <div className={styles.logos}>
    {images?.map((img) => (
      img.link
        ? (
          <AnchorLink
            key={img?.src || ''}
            href={img?.link || ''}
            target="_blank"
            rel="noreferrer"
          >
            <Img
              src={img?.src || ''}
              alt={img?.alternativeText || ''}
              width={img?.width || 0}
              height={img?.height || 0}
            />
          </AnchorLink>
        )
        : (
          <Img
            src={img?.src || ''}
            alt={img?.alternativeText || ''}
            width={img?.width || 0}
            height={img?.height || 0}
          />
        )
    ))}
  </div>
)

export default FooterLogos
