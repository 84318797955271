import { FC } from 'react'

const GermanFlag: FC = () => (
  <svg width="50" height="42" viewBox="0 0 50 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 11C10 10.4477 10.4477 10 11 10H39C39.5523 10 40 10.4477 40 11V17.3333H10V11Z" fill="black" />
    <rect x="10" y="17.3335" width="30" height="7.33333" fill="#CB2B1D" />
    <path d="M10 24.6665H40V30.9998C40 31.5521 39.5523 31.9998 39 31.9998H11C10.4477 31.9998 10 31.5521 10 30.9998V24.6665Z" fill="#F8D045" />
    <defs>
      <filter id="filter0_dd_3555_1035" x="0" y="0" width="50" height="42" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.7625 0 0 0 0 0.7625 0 0 0 0 0.7625 0 0 0 0.12 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3555_1035" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation="5" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_3555_1035" result="effect2_dropShadow_3555_1035" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_3555_1035" result="shape" />
      </filter>
    </defs>
  </svg>
)

export default GermanFlag
