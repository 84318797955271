const SearchGraphic = () => {
  // Add randomly generated gradient id to avoid svg clashes
  // Slice removes "0." from the beginning of the string
  const gradientId = `paint0_linear_${Math.random().toString(36).slice(2, 11)}`

  return (
    <svg width="95" height="95" viewBox="0 0 95 95" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M47.5 95C73.7335 95 95 73.7335 95 47.5C95 21.2665 73.7335 0 47.5 0C21.2665 0 0 21.2665 0 47.5C0 73.7335 21.2665 95 47.5 95Z" fill="url(#paint0_linear_31182_69481)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M76 85.4986C68.0612 91.4616 58.1933 94.9951 47.5 94.9951C36.8067 94.9951 26.9388 91.4616 19 85.4986V29.4297C19 26.116 21.6863 23.4297 25 23.4297H70C73.3137 23.4297 76 26.116 76 29.4297V85.4986Z" fill="white" />
      <path d="M47.5008 63.3297C55.8955 63.3297 62.7008 56.5244 62.7008 48.1297C62.7008 39.735 55.8955 32.9297 47.5008 32.9297C39.1061 32.9297 32.3008 39.735 32.3008 48.1297C32.3008 56.5244 39.1061 63.3297 47.5008 63.3297Z" fill="#054C2E" />
      <path d="M55.7319 69.6641H39.2652C38.2159 69.6641 37.3652 70.5147 37.3652 71.5641C37.3652 72.6134 38.2159 73.4641 39.2652 73.4641H55.7319C56.7812 73.4641 57.6319 72.6134 57.6319 71.5641C57.6319 70.5147 56.7812 69.6641 55.7319 69.6641Z" fill="#054C2E" />
      <path d="M61.4327 77.2656H33.566C32.5167 77.2656 31.666 78.1163 31.666 79.1656C31.666 80.215 32.5167 81.0656 33.566 81.0656H61.4327C62.482 81.0656 63.3327 80.215 63.3327 79.1656C63.3327 78.1163 62.482 77.2656 61.4327 77.2656Z" fill="#054C2E" />
      <path fillRule="evenodd" clipRule="evenodd" d="M52.2486 46.5901C52.2486 43.4639 49.7143 40.9297 46.5882 40.9297C43.462 40.9297 40.9277 43.4639 40.9277 46.5901C40.9277 49.7163 43.462 52.2505 46.5882 52.2505C49.7143 52.2505 52.2486 49.7163 52.2486 46.5901ZM41.9569 46.5901C41.9569 44.0323 44.0304 41.9589 46.5882 41.9589C49.1459 41.9589 51.2194 44.0323 51.2194 46.5901C51.2194 49.1479 49.1459 51.2214 46.5882 51.2214C44.0304 51.2214 41.9569 49.1479 41.9569 46.5901Z" fill="white" />
      <path d="M46.5882 41.4297C49.4382 41.4297 51.7486 43.7401 51.7486 46.5901H52.7486C52.7486 43.1878 49.9905 40.4297 46.5882 40.4297V41.4297ZM41.4277 46.5901C41.4277 43.7401 43.7381 41.4297 46.5882 41.4297V40.4297C43.1858 40.4297 40.4277 43.1878 40.4277 46.5901H41.4277ZM46.5882 51.7505C43.7381 51.7505 41.4277 49.4401 41.4277 46.5901H40.4277C40.4277 49.9924 43.1858 52.7505 46.5882 52.7505V51.7505ZM51.7486 46.5901C51.7486 49.4401 49.4382 51.7505 46.5882 51.7505V52.7505C49.9905 52.7505 52.7486 49.9924 52.7486 46.5901H51.7486ZM46.5882 41.4589C43.7542 41.4589 41.4569 43.7562 41.4569 46.5901H42.4569C42.4569 44.3085 44.3065 42.4589 46.5882 42.4589V41.4589ZM51.7194 46.5901C51.7194 43.7562 49.4221 41.4589 46.5882 41.4589V42.4589C48.8698 42.4589 50.7194 44.3085 50.7194 46.5901H51.7194ZM46.5882 51.7214C49.4221 51.7214 51.7194 49.424 51.7194 46.5901H50.7194C50.7194 48.8717 48.8698 50.7214 46.5882 50.7214V51.7214ZM41.4569 46.5901C41.4569 49.424 43.7542 51.7214 46.5882 51.7214V50.7214C44.3065 50.7214 42.4569 48.8717 42.4569 46.5901H41.4569Z" fill="white" />
      <path d="M49.8265 49.8226C50.0051 49.644 50.2824 49.6241 50.483 49.7631L50.5542 49.8226L55.1855 54.4538C55.3864 54.6548 55.3864 54.9806 55.1855 55.1816C55.0069 55.3602 54.7296 55.3801 54.529 55.2411L54.4577 55.1816L49.8265 50.5503C49.6255 50.3494 49.6255 50.0236 49.8265 49.8226Z" fill="white" stroke="white" />
      <defs>
        <linearGradient id="paint0_linear_31182_69481" x1="47.5" y1="0" x2="47.5" y2="95" gradientUnits="userSpaceOnUse">
          <stop stopColor="#B1F946" stopOpacity="0.19" />
          <stop offset="1" stopColor="#B1F946" stopOpacity="0.39" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default SearchGraphic
