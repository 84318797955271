import { Notification, toaster } from 'rsuite'
import { PlacementType } from 'rsuite/esm/toaster/ToastContainer'

import { ALERT_DURATION } from './constants'
import { ApiError, isApiError, isResponseError } from '../lib/responseHandlers'

export const showNotification = (
  type: 'error' | 'success',
  header: string,
  message: string | JSX.Element,
  placement: PlacementType = 'bottomCenter',
) => {
  toaster.push((
    <Notification
      type={type}
      header={<b>{header}</b>}
      duration={ALERT_DURATION}
    >
      {message}
    </Notification>
  ), { placement })
}

export const showErrorNotification = (
  header: string,
  message: string,
  placement?: PlacementType,
) => showNotification('error', header, message, placement)

export const showApiErrorNotification = (
  error: ApiError,
  placement?: PlacementType,
) => showErrorNotification(error.message, error.errors.map((e) => e.title).join(', '), placement)

export const showErrorBasedNotification = (
  error: unknown,
  placement?: PlacementType,
) => {
  if (isApiError(error)) {
    showApiErrorNotification(error, placement)
    return
  }

  if (isResponseError(error)) {
    showErrorNotification(`Error ${error.status}`, error.message, placement)
    return
  }

  if (error instanceof Error) {
    showErrorNotification('Error!', error.message, placement)
    return
  }

  showErrorNotification('Error!', 'Something went wrong. Please try again later.', placement)
}
