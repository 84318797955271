import React, { FC } from 'react'
import { Icon } from '@rsuite/icons'
import { IconProps } from '@rsuite/icons/lib/Icon'

const OrderListSvg = React.forwardRef<SVGSVGElement>((props, ref) => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg" ref={ref}>
    <path d="M11.4764 1.13652H11.4763L3.54297 1.13652L3.54291 1.13652C3.0367 1.13708 2.55138 1.33841 2.19343 1.69636C1.83548 2.05431 1.63415 2.53963 1.63359 3.04584V3.0459L1.63359 13.9542L1.63359 13.9543C1.63415 14.4605 1.83548 14.9458 2.19343 15.3038C2.55138 15.6617 3.0367 15.8631 3.54291 15.8636H3.54297H8.65005C8.76194 15.8636 8.86925 15.8192 8.94836 15.74C9.02748 15.6609 9.07193 15.5536 9.07193 15.4417C9.07193 15.3298 9.02748 15.2225 8.94836 15.1434C8.86925 15.0643 8.76194 15.0199 8.65005 15.0199H3.54302C3.26048 15.0195 2.98961 14.9072 2.78982 14.7074C2.59003 14.5076 2.47766 14.2367 2.47734 13.9542V3.04595C2.47766 2.76341 2.59003 2.49254 2.78982 2.29275C2.98961 2.09296 3.26048 1.98059 3.54302 1.98027H11.4762C11.7588 1.98059 12.0297 2.09296 12.2295 2.29275C12.4292 2.49254 12.5416 2.76341 12.5419 3.04595V8.11331C12.5419 8.2252 12.5864 8.33251 12.6655 8.41163C12.7446 8.49074 12.8519 8.53519 12.9638 8.53519C13.0757 8.53519 13.183 8.49074 13.2621 8.41163C13.3412 8.33251 13.3857 8.2252 13.3857 8.11331V3.0459V3.04584C13.3851 2.53963 13.1838 2.05431 12.8258 1.69636C12.4679 1.33841 11.9826 1.13708 11.4764 1.13652Z" strokeWidth="0.1" />
    <path d="M4.53203 5.50098H10.482C10.6072 5.50098 10.7272 5.45126 10.8157 5.36277C10.9042 5.27427 10.9539 5.15425 10.9539 5.0291C10.9539 4.90395 10.9042 4.78393 10.8157 4.69544C10.7272 4.60694 10.6072 4.55723 10.482 4.55723H4.53203C4.40688 4.55723 4.28686 4.60694 4.19837 4.69544C4.10987 4.78393 4.06016 4.90395 4.06016 5.0291C4.06016 5.15425 4.10987 5.27427 4.19837 5.36277C4.28686 5.45126 4.40688 5.50098 4.53203 5.50098Z" strokeWidth="0.2" />
    <path d="M9.96224 8.4998C9.96224 8.37466 9.91253 8.25463 9.82403 8.16614C9.73554 8.07764 9.61551 8.02793 9.49037 8.02793H4.53203C4.40688 8.02793 4.28686 8.07764 4.19837 8.16614C4.10987 8.25463 4.06016 8.37466 4.06016 8.4998C4.06016 8.62495 4.10987 8.74498 4.19837 8.83347C4.28686 8.92196 4.40688 8.97168 4.53203 8.97168H9.49037C9.61551 8.97168 9.73554 8.92196 9.82403 8.83347C9.91253 8.74498 9.96224 8.62495 9.96224 8.4998Z" strokeWidth="0.2" />
    <path d="M4.53203 11.4986C4.40688 11.4986 4.28686 11.5483 4.19837 11.6368C4.10987 11.7253 4.06016 11.8454 4.06016 11.9705C4.06016 12.0957 4.10987 12.2157 4.19837 12.3042C4.28686 12.3927 4.40688 12.4424 4.53203 12.4424H7.50703C7.63218 12.4424 7.7522 12.3927 7.8407 12.3042C7.92919 12.2157 7.97891 12.0957 7.97891 11.9705C7.97891 11.8454 7.92919 11.7253 7.8407 11.6368C7.7522 11.5483 7.63218 11.4986 7.50703 11.4986H4.53203Z" strokeWidth="0.2" />
    <path d="M12.1547 16.1815C12.8775 16.1815 13.5841 15.9671 14.1851 15.5656C14.7862 15.164 15.2546 14.5932 15.5312 13.9254C15.8078 13.2576 15.8802 12.5227 15.7392 11.8138C15.5982 11.1048 15.2501 10.4536 14.739 9.94251C14.2279 9.43139 13.5766 9.08331 12.8677 8.9423C12.1588 8.80128 11.4239 8.87365 10.7561 9.15027C10.0883 9.42689 9.51751 9.89532 9.11593 10.4963C8.71435 11.0973 8.5 11.8039 8.5 12.5268C8.50105 13.4957 8.88643 14.4247 9.57159 15.1099C10.2568 15.795 11.1857 16.1804 12.1547 16.1815ZM12.1547 9.68423C12.7169 9.68423 13.2665 9.85094 13.7339 10.1633C14.2014 10.4756 14.5657 10.9196 14.7809 11.439C14.996 11.9584 15.0523 12.5299 14.9426 13.0813C14.833 13.6327 14.5622 14.1392 14.1647 14.5368C13.7672 14.9343 13.2607 15.205 12.7093 15.3147C12.1579 15.4244 11.5863 15.3681 11.0669 15.1529C10.5475 14.9378 10.1036 14.5735 9.79121 14.106C9.47887 13.6386 9.31216 13.089 9.31216 12.5268C9.31299 11.7731 9.61274 11.0506 10.1456 10.5177C10.6785 9.98481 11.4011 9.68506 12.1547 9.68423Z" strokeWidth="0.1" />
    <path d="M11.0592 12.9374H11.7438V13.6219C11.7438 13.7309 11.7871 13.8354 11.8641 13.9124C11.9411 13.9894 12.0456 14.0327 12.1545 14.0327C12.2635 14.0327 12.3679 13.9894 12.445 13.9124C12.522 13.8354 12.5653 13.7309 12.5653 13.6219V12.9374H13.2499C13.3588 12.9374 13.4633 12.8941 13.5403 12.817C13.6174 12.74 13.6606 12.6355 13.6606 12.5266C13.6606 12.4177 13.6174 12.3132 13.5403 12.2362C13.4633 12.1591 13.3588 12.1158 13.2499 12.1158H12.5653V11.4313C12.5653 11.3223 12.522 11.2178 12.445 11.1408C12.3679 11.0638 12.2635 11.0205 12.1545 11.0205C12.0456 11.0205 11.9411 11.0638 11.8641 11.1408C11.7871 11.2178 11.7438 11.3223 11.7438 11.4313V12.1158H11.0592C10.9503 12.1158 10.8458 12.1591 10.7687 12.2362C10.6917 12.3132 10.6484 12.4177 10.6484 12.5266C10.6484 12.6355 10.6917 12.74 10.7687 12.817C10.8458 12.8941 10.9503 12.9374 11.0592 12.9374Z" />
  </svg>

))

const OrderListIcon: FC<IconProps> = (props) => (
  <Icon {...props} as={OrderListSvg} />
)

export default OrderListIcon
