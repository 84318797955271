import { Dispatch, FC, SetStateAction } from 'react'
import { Button, FlexboxGrid, Stack } from 'rsuite'
import CloseIcon from '@rsuite/icons/Close'
import useTranslation from 'next-translate/useTranslation'
import { useFeatureValue } from '@growthbook/growthbook-react'

import useUrls from '../../../services/useUrls'
import { useCart } from '../../Cart/CartProvider'
import { selectCartProductsUnique } from '../../Cart/selectors'
import { formatPriceNumber } from '../../../utils/math'
import { getProductCurrency } from '../../../lib/product'
import { Product } from '../../../utils/types/Product'
import AnchorLink from '../../AnchorLink'

import styles from '../../../styles/CartPopup.module.less'

export interface CartPopupWrapperProps {
  closePopover?: () => void
  isForHover?: boolean
  setIsCartPopupHovered?: Dispatch<SetStateAction<boolean>>
}

const CartPopupWrapper: FC<CartPopupWrapperProps> = (props) => {
  const {
    isForHover,
    closePopover,
    children,
    setIsCartPopupHovered,
  } = props

  const { cartState: { cart } } = useCart()
  const products = cart ? selectCartProductsUnique(cart) : []
  const { t } = useTranslation('common')
  const { urlT } = useUrls()

  const totalCartPrice = formatPriceNumber(Number(cart?.data?.attributes['order.price']))
  const currency = getProductCurrency(products?.[0].product as Product)

  const title = t('cart:{{count}} products in cart', { count: products.length })

  const handleClose = () => {
    if (closePopover) closePopover()
    if (setIsCartPopupHovered) setIsCartPopupHovered(false)
  }

  const checkoutButtonText = useFeatureValue('spp-move-to-checkout-button-text', t('Move to checkout'))

  return (
    <>
      <Stack alignItems="baseline">
        <Stack.Item grow={1}>
          <h5 className="margin-bottom-spacer-tripple">{title}</h5>
        </Stack.Item>
        <Stack.Item>
          {!isForHover && <CloseIcon className="cursor-pointer" onClick={handleClose} />}
        </Stack.Item>
      </Stack>
      {children}
      <FlexboxGrid justify="space-between" align="middle" className={styles['cart-popup-footer']}>
        <FlexboxGrid.Item className="flex-shrink-col-dynamic">
          <b className="nowrap-text">
            {t('Total')}
            {' '}
            {totalCartPrice}
            {' '}
            {currency}
          </b>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item className="flex-shrink-col-static margin-left-spacer-double">
          <AnchorLink href={urlT('/cart')}>
            <Button className={styles['checkout-button']} appearance="primary" size="lg">
              {checkoutButtonText}
            </Button>
          </AnchorLink>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </>
  )
}

export default CartPopupWrapper
