/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
// Above eslint rules are disabled because of using <a> in <SafeTranslate> component
import { FC, KeyboardEvent, ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import { AutoComplete, Col, Modal, Popover, Stack, Whisper } from 'rsuite'
import omit from 'lodash/omit'
import LegacySearchIcon from '@rsuite/icons/legacy/Search'
import { createPortal } from 'react-dom'
import { PickerInstance } from 'rsuite/esm/Picker'
import { Index, InstantSearch } from 'react-instantsearch'
import { useFeature, useFeatureIsOn } from '@growthbook/growthbook-react'

import useIsOnMobile from '../../../../services/useIsOnMobile'
import useUrls from '../../../../services/useUrls'
import { getCountryAndLocaleStrings } from '../../../../utils/locales'
import { QueryParam, ScreenSize } from '../../../../utils/constants'
import { getRefDimension } from '../../../../utils/util'
import useRFQModal from '../../../Modals/hooks/useRFQModal'
import useGetAnalyticsLocation from '../../../../services/analytics/useGetAnalyticsLocation'
import { AnalyticsSubLocation } from '../../../../utils/types/analytics'
import SearchIconBorder from '../../../Icons/SearchIconBorder'
import useMounted from '../../../../services/useMounted'
import SearchPopoverContent from './SearchPopoverContent'
import SafeTranslate from '../../../common/SafeTranslate'
import SearchBoxRsuite from '../../../Search/SearchBoxRsuite'
import ProductHits from '../../../Search/ProductHits'
import SupplierHits from '../../../Search/SupplierHits'
import { defaultConfig, typesenseInstantSearchAdapter } from '../../../../utils/typesense/config'

import styles from '../../../../styles/Search.module.less'
import buttonStyles from '../../../../styles/CustomButtons.module.less'

interface DropdownMenuData {
  value: string
  label: string
}

interface SearchBarProps {
  externalSearchCallback?: () => void
  className?: string
  graphicRenderCondition?: 'focus' | 'always'
  mobileIsInSearchMode?: boolean
}

const hasSearchTermSuffix = (searchedValue: string, suffixes: string[]) => (
  suffixes.some((suffix) => searchedValue.endsWith(suffix))
)

const SearchBar: FC<SearchBarProps> = (props) => {
  const {
    // If there is any callback being passed as a prop, for example to clear some state in the
    // search container - in the case of the mobile search logic, fire it when selecting the
    // search catalog.
    externalSearchCallback = () => {},
    className = '',
    graphicRenderCondition = 'always',
    mobileIsInSearchMode = true,
  } = props

  const [searchTerm, setSearchTerm] = useState('')
  const [isDropdownActive, setIsDropdownActive] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  const isMounted = useMounted()
  const isNewSearch = useFeatureIsOn('use-typesense-search')
  const { value: typesensePreset } = useFeature<string>('typesense-search-preset')

  const { t } = useTranslation('common')
  const { pushT } = useUrls()
  const { asPath, query, isReady, locale: countryAndLocale } = useRouter()
  const { locale } = getCountryAndLocaleStrings(countryAndLocale)
  const searchQuery = query[QueryParam.search]

  // When clearing search, pass all the queries apart from the search and filters
  const queriesWithoutSearchAndFilters = omit(query, [QueryParam.search, QueryParam.filters])

  // NOTE: Custom breakpoint for displaying the mobile header earlier
  // in order to avoid horizontal scrolling
  const isOnMobile = useIsOnMobile(ScreenSize.lg)

  const containerRef = useRef<HTMLDivElement>(null)
  const containerWidth = getRefDimension('width', containerRef)
  const searchInputRef = useRef<PickerInstance>(null)
  const searchInputElement = searchInputRef.current?.root?.getElementsByTagName('input')[0]

  const analyticsLocation = useGetAnalyticsLocation(AnalyticsSubLocation.SearchPopover)
  const openRFQModal = useRFQModal({ analyticsLocation })

  // Used for the search dropdown menu
  const searchMenuData: DropdownMenuData[] = [{
    value: searchTerm,
    label: searchTerm,
  }]

  useEffect(() => {
    // Change to localized preset
    typesenseInstantSearchAdapter.updateConfiguration({
      ...defaultConfig,
      collectionSpecificSearchParameters: {
        ...defaultConfig.collectionSpecificSearchParameters,
        products: {
          preset: `${typesensePreset ?? 'default_products'}_${locale}`,
          limit: defaultConfig.collectionSpecificSearchParameters.products.limit,
        },
      },
    })
  }, [locale, typesensePreset])

  useEffect(() => {
    if (isReady && searchQuery && typeof searchQuery === 'string') {
      setSearchTerm(searchQuery)
    } else {
      setSearchTerm('')
    }
  }, [searchQuery, isReady])

  const formatSearchTerm = (val: string) => {
    let searchedValue = val
    searchedValue = searchedValue.replace(/[@&<>*~"'`,.-]/g, ' ').trim()
    const inputStringArray = searchedValue.split(' ')
    /* The below statement is checking if end term is one of the mentioned
    * than remove that as well as the before term if it's strictly a number
    */
    const suffixArray = [
      t('products:pair_one'),
      t('products:pair_other'),
      t('products:pc_one'),
      t('products:pc_other'),
      t('products:carton_one'),
      t('products:carton_other'),
      'Stk', // inside local de there is '.' which will not match as . is stripped of, so manually added Stk without '.'
    ]

    if (hasSearchTermSuffix(searchedValue, suffixArray)) {
      if (inputStringArray.length > 2) {
        const isStringBeforeUnit = Number.isNaN(parseInt(
          inputStringArray[inputStringArray.length - 2], 10,
        ))
        const replacementString = `${!isStringBeforeUnit ? inputStringArray[inputStringArray.length - 2] : ''} ${inputStringArray[inputStringArray.length - 1]}`
        searchedValue = searchedValue.replace(replacementString, '')
      }
    }

    return searchedValue.trim()
  }

  const pushNewPathOnSearchUpdate = (urlPath: string, hasSearchParam = true) => {
    // If search value was passed, apply timeout to persist the value on the search input
    if (hasSearchParam && searchTerm) {
      setTimeout(() => {
        setSearchTerm(searchTerm)
      }, 0)
    }

    pushT({
      pathname: urlPath,
      query: {
        ...(hasSearchParam && searchTerm
          ? { [QueryParam.search]: formatSearchTerm(searchTerm) }
          : queriesWithoutSearchAndFilters
        ),
      },
    },
    undefined,
    { shallow: true })
  }

  const triggerSearch = () => {
    pushNewPathOnSearchUpdate('/products')
    externalSearchCallback()
    setIsDropdownActive(false)
    searchInputElement?.blur()
  }

  const handleEnterKeyPress = useCallback((event: KeyboardEvent<Element>) => {
    if (event.key !== 'Enter') return

    triggerSearch()
  }, [locale, searchTerm])

  const handleClearSearchClick = () => {
    setSearchTerm('')

    const [urlWithoutParams] = asPath.split('?')
    pushNewPathOnSearchUpdate(urlWithoutParams, false)

    // Auto focus input when search is cleared
    searchInputElement?.focus()
  }

  const handleChange = (val: string) => setSearchTerm(val)

  const renderSearchGraphic = () => {
    if (graphicRenderCondition === 'always') {
      return <SearchPopoverContent className="margin-top-spacer-tripple margin-bottom-spacer" />
    }

    if (graphicRenderCondition === 'focus' && document.activeElement === searchInputElement) {
      return <SearchPopoverContent className="margin-top-spacer-tripple margin-bottom-spacer" />
    }

    return null
  }

  const renderSearchMenuItemLabel = (
    label: ReactNode,
  ) => (
    <div data-testid="search-dropdown">
      <button
        type="button"
        onClick={triggerSearch}
        className={styles['search-term']}
        data-testid="search-option"
      >
        <SearchIconBorder />
        <p>
          <SafeTranslate
            i18nKey='common:Search for "{{ query }}" in products'
            components={[<b />]}
            values={{ query: label }}
          />
        </p>
      </button>
      <p className={styles['call-to-action']}>
        <SafeTranslate
          i18nKey="modals:Can't find a suitable product? Save time and request an offer in a second"
          components={[<button
            type="button"
            className={buttonStyles['link-button']}
            onClick={() => {
              openRFQModal()
            }}
          />]}
        />
      </p>
    </div>
  )

  const searchInput = (
    <div className="relative">
      <AutoComplete
        ref={searchInputRef}
        open={!!searchTerm.length && isDropdownActive}
        data={searchMenuData}
        onChange={!isNewSearch ? handleChange : undefined}
        onClick={() => setIsOpen(true)}
        onFocus={() => {
          if (isNewSearch) {
            searchInputElement?.blur()
          }
        }}
        onKeyUp={handleEnterKeyPress}
        container={containerRef.current || undefined}
        className="input-round"
        menuClassName={styles['auto-complete-menu']}
        placement="bottomStart"
        data-testid="search-input"
        placeholder={t('Search for product, category or supplier')}
        value={searchTerm}
        renderMenuItem={(label: ReactNode) => (
          renderSearchMenuItemLabel(label)
        )}
      />
      {searchTerm && (
      // Copied markup from rs-picker, inherits styles from global scope
      <button
        className="rs-picker-toggle-clean"
        type="button"
        tabIndex={-1}
        onClick={handleClearSearchClick}
        data-testid="clear-btn"
      >
        ✕
      </button>
      )}
      <LegacySearchIcon
        className={styles['search-icon']}
        role="button"
        data-testid="search-icon"
        onClick={() => searchTerm && triggerSearch()}
      />
    </div>
  )

  return (
    <div ref={containerRef} className={`${styles['search-container']} ${className}`}>
      {
        // Transparent backdrop
        // isMounted needed to ensure window.document is available
        isMounted && createPortal(
          <div className={`${styles['search-backdrop']} ${(isDropdownActive) ? styles.visible : ''}`} />,
          document.body,
        )
      }
      <Modal
        open={isOpen && isNewSearch}
        size="md"
        onClose={() => {
          setIsOpen(false)
          // Defocus header input when closing modal to not allow editing the text in it
          setTimeout(() => searchInputElement?.blur(), 100)
        }}
        dialogClassName={styles['search-modal-dialog']}
      >
        <InstantSearch indexName="products" searchClient={typesenseInstantSearchAdapter.searchClient}>
          <Stack direction="column" className={styles['search-modal-container']}>
            <Stack.Item>
              <SearchBoxRsuite
                gotoSearchPage={(searchQuery_: string) => {
                  setIsOpen(false)
                  externalSearchCallback()
                  pushT({
                    pathname: '/products',
                    query: {
                      ...(searchQuery_
                        ? { [QueryParam.search]: formatSearchTerm(searchQuery_) }
                        : queriesWithoutSearchAndFilters
                      ),
                    },
                  },
                  undefined,
                  { shallow: true })
                }}
              />
            </Stack.Item>
            <Stack.Item>
              <ProductHits closeModal={() => setIsOpen(false)} />
            </Stack.Item>
            <Stack.Item>
              <Index indexName="suppliers">
                <SupplierHits closeModal={() => setIsOpen(false)} />
              </Index>
            </Stack.Item>
          </Stack>
        </InstantSearch>
      </Modal>

      {!isOnMobile && (
        <Whisper
          placement="bottomStart"
          enterable
          open={isDropdownActive}
          speaker={(
            <Popover
              arrow={false}
              // Set width dynamically
              style={{ width: `${containerWidth}px` }}
              className={`${styles['mobile-popover-wrapper']} ${searchTerm.length > 0 ? styles.hidden : ''}`}
            >
              <SearchPopoverContent />
            </Popover>
          )}
          onFocus={isNewSearch
            ? undefined
            : () => {
              setIsDropdownActive(true)
            }}
          onBlur={isNewSearch
            ? undefined
            : () => {
              // Set timeout to prevent popover from closing when clicking on it
              // If the search input is focused, the popover should not close
              setTimeout(() => {
                if (searchInputElement === document.activeElement) return
                setIsDropdownActive(false)
              }, 100)
            }}
        >
          {searchInput}
        </Whisper>
      )}

      {isOnMobile && (
        <>
          {searchInput}
          {mobileIsInSearchMode && searchTerm
            ? (
              <div className={styles['mobile-search-dropdown']}>
                {searchMenuData.map((item) => (
                  <Col xs={24} key={item.value}>
                    {renderSearchMenuItemLabel(item.label)}
                  </Col>
                ))}
              </div>
            )
            : renderSearchGraphic()}
        </>
      )}
    </div>
  )
}

export default SearchBar
