import { isProductInclude } from '../../../utils/types/guards/Order'
import { OrderInclude } from '../../../utils/types/Order'

export default (cartInclude: OrderInclude[]) => [...new Set(
  cartInclude
    ?.filter(isProductInclude)
    ?.map((item) => (item.attributes['order.product.type'] === 'select'
      ? item.attributes['order.product.parentproductid']
      : item.attributes['order.product.productid'])) || [],
)]
