import TagManager from 'react-gtm-module'

import { getHashedUserId } from './analytics'
import { UserI } from '../types/auth'
import getUserType from './getUserType'

const setDatalayerUserId = async (user: UserI | null) => {
  const { hashedId: userId } = user ? await getHashedUserId(user.email) : { hashedId: null }
  const userType = getUserType(user)

  TagManager.dataLayer({
    dataLayer: {
      user_id: userId,
      user_type: userType,
    },
  })
}

export default setDatalayerUserId
