import { deserialize, DocumentObject } from 'jsonapi-fractal'
import merge from 'lodash/merge'
import isEqual from 'lodash/isEqual'
import cloneDeep from 'lodash/cloneDeep'

import {
  hasIncluded,
  hasLinks,
  hasRelationships,
  ICartLinks,
  IOptionsDocument,
  IRelationshipObject,
  IResourceIdentifierObject,
  IResourceObject,
  IResponseDocument,
  isGetSuccess,
  isSuccess,
  SearchResourceSuccess,
  SearchResourceSuccessItemResponse as GQLSuccessItemResponse,
} from '../utils/types/aimeosApi'
import {
  Catalog,
  PartialProduct,
  Product,
  ProductGql,
  ResourceGql,
  Service,
  ProductAttribute,
  Supplier,
  ProductStock,
  RuleGql,
  Customer,
  CatalogGqlData,
  ProductStockGqlData,
  RuleGqlData,
  AttributeGqlData,
  SupplierGqlData,
} from '../utils/types/Product'
import { isDefined } from '../utils/types/misc'
import fetcher from '../utils/fetcher'
import { getSaveCustomerMutationQuery, getSaveProductMutationQuery } from '../utils/graphqlConstants'
import { Resource, ResourceName, ResourceNameMap } from '../utils/types/Resource'

export type ResourceList<T = any> = Partial<Record<ResourceName, T[]>>

export interface UseApiConfig {
  queryString: string
  options: IOptionsDocument
}

interface ParsedResponse<T extends Resource> {
  data: T[]
  isLoading: boolean
  error: any
  links: ICartLinks[]
}

export const recordToQueryString = (record: Record<string, string>) => (
  Object.entries(record).map(([k, v]) => `${k}=${v}`))

/**
 * Temporary helper to recover data from non-JSONAPI parts from newer more compact version.
 * This should be removed as quickly as posisble in followup PR when the data is used directly
 * @param resp
 * @param products
 */
const rebuildResponse = (resp: IResponseDocument | undefined, products: Product[]): Product[] => {
  if (resp && 'data' in resp) {
    [resp.data].flat().forEach((dataItem) => {
      const product = products.find((prod) => prod.id === dataItem?.id)
      if (product) {
        if (product.product && 'included' in resp) {
          product.product = product.product.map((prod) => {
            const rawProdData = resp?.included?.find((included) => included.type === 'product' && included.id === prod.id)
            if (rawProdData) {
              return {
                ...prod,
                'product/property': Array.isArray(
                  rawProdData?.attributes?.['product.property'],
                ) ? rawProdData?.attributes?.['product.property']?.map(
                    (property) => ({
                      id: '',
                      'product.property.id': '',
                      'product.property.type': Object.keys(property ?? {})[0],
                      'product.property.value': Object.values(property ?? {})[0],
                      'product.property.languageid': null,
                    }),
                  ) ?? [] : [],
              }
            }
            return prod
          })
        }

        product['product/property'] = [
          ...(product['product/property'] ?? []),
          ...(Array.isArray(dataItem?.attributes?.['product.property']) ? dataItem?.attributes?.['product.property']?.map((property) => ({
            id: '',
            'product.property.id': '',
            'product.property.type': Object.keys(property ?? {})[0],
            'product.property.value': Object.values(property ?? {})[0],
            'product.property.languageid': null,
          })) ?? [] : []),
        ]
      }
    })
  }

  return products
}

export const parseResponse = <T extends Resource>(
  respDataFull: IResponseDocument | undefined,
  respError?: any,
): ParsedResponse<T> => {
  if (typeof respDataFull !== 'object') return { data: [], isLoading: true, error: [], links: [] }

  // Clone resp to prevent jsonapi-fractal modifying the passed object.
  // This might cause lots of unwanted issues, for example inflating SSG data sizes remarkably
  const respData = cloneDeep(respDataFull)

  // TODO: for some reason some included id's are numbers and not strings, breaking deserialize
  if (respData && typeof respData !== 'number' && 'included' in respData && respData.included) {
    respData.included.forEach((inclObj) => {
      // eslint-disable-next-line no-param-reassign
      inclObj.id = inclObj.id.toString()

      // TODO: Fix relationship and type mismatch in aimeos for properties
      // attribute.property vs attribute/property, Not fixed as well
      // Solutuion (create a custom api, or PR to fix it in aimeos)
      if ('relationships' in inclObj) {
        const { type } = inclObj
        if (inclObj.relationships && `${type}.property` in inclObj.relationships && inclObj.relationships[`${type}.property`].data) {
          const relData = inclObj.relationships[`${type}.property`].data
          if (Array.isArray(relData)) {
            // eslint-disable-next-line no-param-reassign
            inclObj.relationships[`${type}/property`] = {
              data: relData?.map((obj) => ({
                ...obj,
                type: `${type}/property`,
              })),
            }
          }
        }
      }
    })
  }
  // TODO: Also the ids in relationships object can sometimes be numbers and need to be stringified
  if (respData && isSuccess(respData)) {
    if (Array.isArray(respData.data) && respData.data.length > 0) {
      respData.data.forEach((resource) => {
        if (resource && 'relationships' in resource && resource.relationships) {
          Object.keys(resource.relationships).forEach((resourceType) => {
            const relations = resource?.relationships?.[resourceType]?.data
            if (Array.isArray(relations)) {
              relations.forEach((relation) => {
                // eslint-disable-next-line no-param-reassign
                relation.id = relation.id.toString()
              })
            } else if (relations) {
              relations.id = relations.id.toString()
            }
          })
        }
      })
    }
  }
  // TODO: Remove when PR for aimeos fix (catalog.relationships is formatted wrong) is merged
  if (respData && isSuccess(respData)) {
    if (Array.isArray(respData.data) && respData.data.length > 0) {
      if (respData.data[0]?.type === 'catalog' && Array.isArray(respData.data)) {
        respData.data.forEach((catalog: IResourceObject | null) => {
          if (catalog && catalog.relationships) {
            // eslint-disable-next-line no-param-reassign
            catalog.relationships = Object.fromEntries(
              Object.entries(catalog.relationships).map(([key, value]) => {
                if (Array.isArray(value)) {
                  const newVal = { data: value.map((relation) => relation.data) }
                  return [key, newVal]
                }
                return [key, value]
              }),
            )
          }
        })
      }
    }
  }

  // TODO: Hacky fix
  // jsonapi-fractal can cause problems when parsing a resource that is related to itself
  // If the related one has not been parsed before being related to another resource,
  // it will use a version of the resource without any of its own relationships
  // Eg. a variant product will be missing all of its own relationships (attributes/properties)
  // The fix here is to first parse all variants, and only then the main products,
  // Which means that when variants are added to the main products
  // they will have all their own relationships
  // NOTE: This heuristic doesn't solve the problem for all cases - only for variant products
  if (respData && isSuccess(respData)) {
    if (Array.isArray(respData.data) && respData.data.length > 0) {
      if (respData.data[0]?.type === 'product') {
        // eslint-disable-next-line no-param-reassign
        respData.data = respData.data.sort((a, b) => {
          if (a && hasRelationships(a) && b && hasRelationships(b)) {
            const aAttributeRelationships = a.relationships?.attribute?.data
            const bAttributeRelationships = b.relationships?.attribute?.data
            if (Array.isArray(aAttributeRelationships) && Array.isArray(bAttributeRelationships)) {
              const isAVariant = aAttributeRelationships[0]?.attributes?.['product.lists.type'] === 'variant'
              const isBVariant = bAttributeRelationships[0]?.attributes?.['product.lists.type'] === 'variant'
              if (isAVariant && !isBVariant) {
                return -1
              }
              if (!isAVariant && isBVariant) {
                return 1
              }
            }
          }

          // Will only alter order if one of the compared resources is a variant,
          // e.g. has an attribute with 'product.lists.type' variant
          return 0
        })
      }
    }
  }

  // Re-add/recustruct some data that was stripped to reduce response sizes
  if (isSuccess(respData) && respData.data && Array.isArray(respData.data)) {
    respData.data = respData.data.map((data) => ({
      ...data,
      id: data?.id ?? '',
      type: data?.type ?? '',
      attributes: Object.fromEntries(Object.entries(data?.attributes ?? {}).map(([key, value]) => (
        [key.includes('.') ? key : `${data?.type}.${key}`, value]
      ))),
      relationships: Object.fromEntries(
        Object.entries(
          data && hasRelationships(data) ? data?.relationships ?? {} : {},
        ).map(([key, value]) => (
          [key, {
            data: Array.isArray(value.data) ? value.data?.map((related) => ({
              ...related,
              id: related.id ?? '',
              type: key,
            })) : {
              ...value.data,
              id: value.data?.id ?? '',
              type: key,
            },
          }]
        )),
      ),
    }))
  } else if (isSuccess(respData)
    && respData.data
    && (Array.isArray(respData.data) || typeof respData.data === 'object')
    && 'attributes' in respData.data
    && 'type' in respData.data) {
    respData.data = {
      ...respData.data,
      attributes: Object.fromEntries(
        Object.entries(
          'attributes' in respData.data ? respData.data.attributes ?? {} : {},
        ).map(([key, value]) => (
          [key.includes('.') ? key : `${respData.data && 'type' in respData.data ? respData.data.type : ''}.${key}`, value]
        )),
      ),
      relationships: Object.fromEntries(
        Object.entries(
          hasRelationships(respData.data) ? respData.data.relationships ?? {} : {},
        ).map(([key, value]) => (
          [key, {
            data: Array.isArray(value.data) ? value.data.map((related) => ({
              ...related,
              id: related.id ?? '',
              type: key,
            })) : {
              ...value.data,
              id: value.data?.id ?? '',
              type: key,
            },
          }]
        )),
      ),
    }
  }
  if (hasIncluded(respData) && respData.included) {
    respData.included = respData.included.map((data) => ({
      ...data,
      attributes: Object.fromEntries(Object.entries(data.attributes ?? {}).map(([key, value]) => (
        [key.includes('.') ? key : `${data.type}.${key}`, value]
      ))),
      relationships: Object.fromEntries(
        Object.entries(
          data.relationships ?? {},
        ).map(([key, value]) => (
          [key, {
            data: Array.isArray(value.data) ? value.data?.map((related) => ({
              ...related,
              id: related.id ?? '',
              type: key,
            })) : {
              ...value.data,
              id: value.data?.id ?? '',
              type: key,
            },
          }]
        )),
      ),
    }))
  }

  const data = (
    respData && isSuccess(respData)
      ? deserialize<T>(respData as DocumentObject)
      : []
  ) ?? []
  if (hasIncluded(respData) && respData.included) {
    // jsonapi-fractal modifies param object and adds a 'cached' property
    // Delete this to prevent circular structure
    /* eslint-disable no-param-reassign */
    // @ts-ignore
    delete respData.included.cached
    /* eslint-enable no-param-reassign */
  }
  const dataArray = Array.isArray(data) ? data : [data]
  // Put bought together products as separate field from variants
  const splitData = dataArray.map((resource) => {
    const isProduct = (r: Resource): r is Product => (r as Product)['product.id'] !== undefined
    if (isProduct(resource) && hasLinks(respData) && isGetSuccess(respData) && respData.data) {
      const rawRespDataArray = Array.isArray(respData.data) ? respData.data : [respData.data]
      const resourceData = rawRespDataArray.find((r: IResourceObject) => r.id === resource.id)

      // Record ids of product relationships so that in case of multiple ones to the same
      // product each one is only used once
      // Currently only used for attributes

      const usedLinks = new Set<string>()
      const getLinked = (id: string, type: string): IResourceIdentifierObject | undefined => {
        const relationships = resourceData?.relationships?.[type].data
        if (Array.isArray(relationships)) {
          return relationships?.find((relationship) => {
            const listItemId = relationship.attributes?.['product.lists.id']?.toString() || relationship?.attributes?.id?.toString() || ''
            return relationship.id === id
            && relationship.type === type
            && !usedLinks.has(listItemId)
          })
        }
        const listItemId = relationships?.attributes?.['product.lists.id']?.toString() || relationships?.attributes?.id?.toString() || ''
        if (relationships?.id === id && !usedLinks.has(listItemId)) {
          return relationships
        }
        return undefined
      }

      // Record which relationships are used so that they are used only once
      return {
        ...resource,
        price: resource.price ?? [],
        catalog: resource.catalog ?? [],
        'product/property': resource['product/property'] ?? [],
        // Add lists.type and lists.id to attributes.
        // Used for highlighted features
        // Also parse attribute properties from compact version
        attribute: resource.attribute?.map((attr) => {
          const linked = getLinked(attr.id, 'attribute')
          const listItemId = linked?.attributes?.['product.lists.id']?.toString() ?? linked?.attributes?.id?.toString()
          if (listItemId) {
            usedLinks.add(listItemId)
          }
          return {
            ...attr,
            'attribute/property': attr['attribute.property']
              ?.flatMap((property) => Object.entries(property ?? {}))
              .map(([type, value]) => ({
                'attribute.property.id': '',
                'attribute.property.type': type,
                'attribute.property.value': value,
                'attribute.property.languageid': null,
              })),
            'product.lists.id': listItemId || '',
            'product.lists.type': linked?.attributes?.['product.lists.type'] || linked?.attributes?.type || 'default',
          }
        }) || [],
        // Add the product.lists.position to the media object to determine the position of the
        // image resource in the gallery
        media: resource.media?.map(((attr) => {
          const linked = getLinked(attr.id, 'media')
          return {
            ...attr,
            'product.lists.id': linked?.attributes?.['product.lists.id'] || '',
            'product.lists.position': linked?.attributes?.['product.lists.position'] ?? 100, // fallback large number to place the media resource as last via the position
          }
        })),
        boughtTogether: resource.product?.filter((prod) => (
          (getLinked(prod.id, 'product')?.attributes?.['product.lists.type'] || getLinked(prod.id, 'product')?.attributes?.type) === 'bought-together')) || [],
        product: resource.product?.filter((prod) => (
          (getLinked(prod.id, 'product')?.attributes?.['product.lists.type'] || getLinked(prod.id, 'product')?.attributes?.type) !== 'bought-together'
        )).map((prod) => ({
          ...prod,
          attribute: prod.attribute?.map((attr) => ({
            // Add the lists.type for variant attributes
            ...attr,
            'product.lists.type': 'variant',
          })) || [],
        })) || [],
      }
    }
    return resource
  }).map((resource) => {
    // The service API automatically includes an empty price object if a service
    // does not have a price, clear this to not mess everything up
    const isService = (r: Resource): r is Service => (r as Service)['service.code'] !== undefined
    if (isService(resource) && !Array.isArray(resource.price)) {
      return {
        ...resource,
        price: [],
      }
    }
    // Preserve customer.lists.id for linked products since we sometimes need it
    const isCustomer = (r: Resource): r is Customer => (r as Customer)['customer.id'] !== undefined
    if (isCustomer(resource) && hasLinks(respData) && isGetSuccess(respData) && respData.data) {
      const rawRespDataArray = Array.isArray(respData.data) ? respData.data : [respData.data]
      const resourceData = rawRespDataArray.find((r: IResourceObject) => r.id === resource.id)
      const relationships = resourceData?.relationships?.product?.data
      return {
        ...resource,
        product: resource.product?.map((product) => ({
          ...product,
          'customer.lists.id': [relationships].flat()?.find((relation) => (
            relation && relation.id === product.id && relation.type === 'product'))?.attributes?.['customer.lists.id'],
          'customer.lists.type': [relationships].flat()?.find((relation) => (
            relation && relation.id === product.id && relation.type === 'product'))?.attributes?.['customer.lists.type'],
        })),
      }
    }
    return resource
  })
  let links: ICartLinks[] = []
  if (respData && isSuccess(respData)) {
    if (Array.isArray(respData.data)) {
      links = respData.data
        .map((dataObj: IResourceObject | null) => (dataObj ? dataObj.links : null))
        .filter(isDefined) as ICartLinks[]
    } else {
      links = [respData.data?.links].filter(isDefined) as ICartLinks[]
    }
  }
  const error = respError || (!isSuccess(respData || { data: null }) ? respData : undefined)
  const isProductArray = (d: Resource[]): d is Product[] => (d as Product[])[0]?.['product.id'] !== undefined
  return {
    data: isProductArray(splitData) ? rebuildResponse(respData, splitData) as T[] : splitData,
    isLoading: !respData && !error,
    error,
    links,
  }
}

export const getRelationships = async <R extends Resource>(
  structuredData: R[],
  type: ResourceName,
  config: UseApiConfig,
  method: 'POST' | 'PATCH',
  additionalAttributes?: Record<string, any>,
  relationships?: Record<string, IRelationshipObject>[],
): Promise<{ data: R[], isLoading: boolean, error: any }> => {
  const { queryString, options } = config
  const textData = structuredData.map((relResource, index) => (
    {
      type,
      ...(method === 'PATCH' && relResource.id !== '' && { id: relResource.id }),
      attributes: {
        ...relResource,
        ...(additionalAttributes && additionalAttributes),
      },
      relationships: relationships?.[index],
    }
  ))
  const relUrl = options.meta.resources[type]
  const relResp = await fetcher(`${relUrl}${queryString}`, method, JSON.stringify({ data: textData }))
  const newTextResources: IResponseDocument = await relResp.json()
  return parseResponse<R>(newTextResources)
}

export const resultDataCollector = (): [
  ResourceList<Resource>,
  ResourceList,
  (type: ResourceName, createdData: Resource[], error?: any) => void,
  (data: ResourceList<Resource>, errs: ResourceList) => void,
] => {
  const created: ResourceList<Resource> = {}
  const errors: ResourceList = {}
  const addResultData = (type: ResourceName, createdData: Resource[], error?: any) => {
    if (created[type]) {
      created[type]?.push(...createdData)
    } else {
      created[type] = createdData
    }
    if (error) {
      if (errors[type]) {
        errors[type]?.push(...error)
      } else {
        errors[type] = [error]
      }
    }
  }
  const mergeResults = (data: Record<string, Resource[]>, errs: Record<string, any[]>) => {
    merge(created, data)
    merge(errors, errs)
  }
  return [created, errors, addResultData, mergeResults]
}

export const filterNewChanges = (
  current: PartialProduct,
  old: PartialProduct | undefined,
) => {
  const checkResourceUpdate = (
    resource: ResourceName & keyof Product,
  ) => {
    const oldResource = old?.[resource]
    const currentResource = current?.[resource]

    if (resource === 'product') {
      let isUpdated = false
      const oldVariants = oldResource as Product[] | undefined
      const currentVariants = currentResource as Product[]

      if (oldVariants?.length !== currentVariants.length) {
        isUpdated = true
      }
      currentVariants?.forEach((variant) => {
        const oldVariant = oldVariants?.find((item) => item.id === variant.id)

        if (variant['product.code'] !== oldVariant?.['product.code']
        || variant['product.label'] !== oldVariant?.['product.label']
        || variant.attribute[0]?.['attribute.id'] !== oldVariant?.attribute[0]?.['attribute.id']
        || (currentVariants.findIndex((v) => v === variant)
            !== oldVariants?.findIndex((v) => v === oldVariant))
        ) {
          isUpdated = true
        }

        variant['product/property'].forEach((propertyItem) => {
          const oldProperty = oldVariant?.['product/property'].find((item) => item.id === propertyItem.id)
          if (propertyItem['product.property.value'] !== oldProperty?.['product.property.value']) {
            isUpdated = true
          }
        })
      })

      return isUpdated ? current[resource] : []
    }

    return isEqual(
      oldResource?.sort((a, b) => Number(a.id) - Number(b.id)),
      currentResource?.sort((a, b) => Number(a.id) - Number(b.id)),
    )
      ? []
      : current[resource]
  }

  // Case when user clicks save product button instantly after saving a product already
  if (!old) {
    return ({
      id: current.id,
      'product.status': current['product.status'],
      'product.code': old?.['product.code'] !== current['product.code'] ? current['product.code'] : '',
      'product.label': old?.['product.label'] !== current['product.label'] ? current['product.label'] : '',
      catalog: [],
      text: [],
      media: [],
      price: [],
      stock: [],
      product: [],
      attribute: [],
      'product/property': [],
    }) as PartialProduct
  }
  return ({
    id: current.id,
    'product.status': current['product.status'],
    'product.code': old?.['product.code'] !== current['product.code'] ? current['product.code'] : '',
    'product.label': old?.['product.label'] !== current['product.label'] ? current['product.label'] : '',
    catalog: checkResourceUpdate('catalog'),
    text: checkResourceUpdate('text'),
    media: checkResourceUpdate('media'),
    price: checkResourceUpdate('price'),
    stock: checkResourceUpdate('stock'),
    product: checkResourceUpdate('product'),
    attribute: checkResourceUpdate('attribute'),
    'product/property': checkResourceUpdate('product/property'),
  }) as PartialProduct
}

export const decycle = (name: string, data: object[]) => {
  // Break cycles, eg. product.catalog.product.catalog.product....
  data.forEach((obj) => Object.entries(obj).forEach(([, value]) => {
    if (Array.isArray(value)) {
      value.forEach((val) => {
        if (typeof val === 'object' && val !== null && name in val) {
          // eslint-disable-next-line no-param-reassign
          val[name] = []
        }
      })
    }
    if (typeof value === 'object' && value !== null && name in value) {
      // eslint-disable-next-line no-param-reassign
      value[name] = []
    }
  }))
}

// Use object for the type to properly bind types together
export const getGraphQLQuery = <R extends ResourceNameMap>(
  { resource, data, updatedFields }: R,
): string => {
  switch (resource) {
    case 'product':
      return getSaveProductMutationQuery(data, updatedFields)
    case 'customer':
      return getSaveCustomerMutationQuery(data)
    default:
      return ''
  }
}
export const restructureGqlResourceResponse = <ResourceGqlT extends ResourceGql = any>(
  graphQlResponse: SearchResourceSuccess<ResourceGqlT> | undefined,
  resource: ResourceName | null,
) => {
  switch (resource) {
    case 'product':
    {
      const data = graphQlResponse as SearchResourceSuccess<ProductGql> | undefined
      return data?.searchResource?.map((prod) => ({
        id: prod.id,
        'product.code': prod.code,
        'product.status': prod.status,
        'product.siteid': prod.siteid,
        'product.ctime': prod.ctime,
        'product.label': prod.label,
        'product.type': prod.type,

        attribute: prod.lists.attribute.map((attr) => ({
          'attribute.code': attr.item?.code,
          'attribute.id': attr.item?.id,
          id: attr.item?.id,
          'attribute.label': attr.item?.label,
          'attribute.type': attr.item?.type,
          'product.lists.id': attr.id,
          'product.lists.type': attr.type,
        })),

        catalog: prod.lists.catalog.map((cat) => ({
          'catalog.code': cat.item?.code,
          'catalog.id': cat.item?.id,
          id: cat.item?.id,
          'catalog.label': cat.item?.label,
          'catalog.status': cat.item?.status,
          'catalog.level': cat.item?.level,
          'catalog.parentid': cat.item?.parentid,
          'catalog.config': cat.item?.config ? JSON.parse(cat.item?.config) : '{}',
          'catalog.url': cat.item?.url,
          'catalog.lists.position': cat?.position,
        })),

        media: prod.lists.media.map((media) => ({
          'media.id': media.item?.id,
          id: media.item?.id,
          'media.label': media.item?.label,
          'media.url': media.item?.url,
          'media.type': media.item?.type,
          'media.preview': media.item?.preview,
          'media.previews': JSON.parse(media.item?.preview || 'null'),
          'product.lists.position': media?.position,
        })),

        price: prod.lists.price.map((price) => ({
          'price.id': price.item?.id,
          id: price.item?.id,
          'price.label': price.item?.label,
          'price.currencyid': price.item?.currencyid,
          'price.costs': price.item?.costs,
          'price.quantity': price.item?.quantity,
          'price.rebate': price.item?.rebate,
          'price.status': price.item?.status,
          'price.taxrate': price.item?.taxrate,
          'price.taxrates': price.item?.taxrate ? JSON.parse(price.item.taxrate) : '',
          'price.value': price.item?.value,
          'price.type': price.item?.type,
        })),

        text: prod.lists.text.map((text) => ({
          'text.id': text.item?.id,
          id: text.item?.id,
          'text.label': text.item?.label,
          'text.type': text.item?.type,
          'text.content': text.item?.content,
          'text.languageid': text.item?.languageid,
        })),

        stock: prod?.stock?.items?.map((stock) => ({
          id: stock.id,
          'stock.id': stock.id,
          'stock.type': stock.type,
          'stock.productid': stock.productid,
          'stock.stocklevel': stock.stocklevel,
          'stock.timeframe': stock.timeframe,
          'stock.dateback': stock.dateback,
        })),

        'product/property': prod?.property?.map((property) => ({
          id: property.id,
          'product.property.id': property.id,
          'product.property.type': property.type,
          'product.property.value': property.value,
        })),

        product: prod.lists.product
          ?.map((variant) => ({
            id: variant.item?.id,
            'product.id': variant.item?.id,
            'product.code': variant.item?.code,
            'product.status': variant.item?.status,
            'product.type': variant.item?.type,
            'product.label': variant.item?.label,
            'product/property': variant.item?.property?.map((property) => ({
              id: property.id,
              'product.property.id': property.id,
              'product.property.type': property.type,
              'product.property.value': property.value,
            })),
            attribute: variant.item?.lists?.attribute?.map((attr) => ({
              'attribute.code': attr.item?.code,
              'attribute.id': attr.item?.id,
              id: attr.item?.id,
              'attribute.label': attr.item?.label,
              'attribute.type': attr.item?.type,
              'product.lists.type': attr.type,
              'product.lists.id': attr.id,
            })),
            stock: (prod?.stock?.items && prod.stock.items.length > 0)
              ? prod.stock.items.map((stock) => ({
                id: stock.id,
                'stock.id': stock.id,
                'stock.type': stock.type,
                'stock.productid': stock.productid,
                'stock.stocklevel': stock.stocklevel,
                'stock.timeframe': stock.timeframe,
                'stock.dateback': stock.dateback,
              }))
              : undefined,

          })),
      })) as Product[] | undefined
    }

    case 'catalog':
    {
      const data = graphQlResponse as GQLSuccessItemResponse<CatalogGqlData> | undefined
      return data?.searchResource?.items?.map((catalog) => ({
        id: catalog.id,
        'catalog.id': catalog.id,
        'catalog.code': catalog.code,
        'catalog.label': catalog.label,
        'catalog.level': catalog.level,
        'catalog.parentid': catalog.parentid.toString(),
        'catalog.config': catalog?.config ? JSON.parse(catalog?.config) : '{}',
        attribute: catalog?.lists?.attribute.map((attr) => ({
          'attribute.code': attr.item?.code,
          'attribute.id': attr.item?.id,
          id: attr.item?.id,
          'attribute.label': attr.item?.label,
          'attribute.type': attr.item?.type,
          'product.lists.id': attr.id,
          'product.lists.type': attr.type,
          ...(attr.item?.property && { 'attribute/property': attr.item.property?.map((property) => ({
            id: property.id,
            'attribute.property.id': property.id,
            'attribute.property.type': property.type,
            'attribute.property.value': property.value,
          })) }),
        })),
      })) as Catalog[] | undefined
    }

    case 'attribute':
    {
      const data = graphQlResponse as GQLSuccessItemResponse<AttributeGqlData> | undefined
      return data?.searchResource?.items?.map((attribute) => ({
        id: attribute.id,
        'attribute.id': attribute.id,
        'attribute.code': attribute.code,
        'attribute.label': attribute.label,
        'attribute.type': attribute.type,
        ...(attribute?.lists?.attribute && { attribute: attribute?.lists?.attribute.map((attr) => ({
          'attribute.code': attr.item?.code,
          'attribute.id': attr.item?.id,
          id: attr.item?.id,
          'attribute.label': attr.item?.label,
          'attribute.type': attr.item?.type,
          'product.lists.id': attr.id,
          'product.lists.type': attr.type,
        })) }),
        ...(attribute.property && { 'attribute/property': attribute.property?.map((property) => ({
          id: property.id,
          'attribute.property.id': property.id,
          'attribute.property.type': property.type,
          'attribute.property.value': property.value,
        })) }),
      })) as ProductAttribute[] | undefined
    }

    case 'supplier':
    {
      const data = graphQlResponse as GQLSuccessItemResponse<SupplierGqlData> | undefined
      return data?.searchResource?.items?.map((supplier) => ({
        id: supplier.id,
        'supplier.id': supplier.id,
        'supplier.code': supplier.code,
        'supplier.label': supplier.label,
        'supplier.siteid': supplier.siteid,
      })) as Supplier[] | undefined
    }

    case 'stock':
    {
      const data = graphQlResponse as GQLSuccessItemResponse<ProductStockGqlData> | undefined
      return data?.searchResource?.items?.map((stock) => ({
        id: stock.id,
        'stock.id': stock.id,
        'stock.type': stock.type,
        'stock.productid': stock.productid,
        'stock.stocklevel': stock.stocklevel,
        'stock.timeframe': stock.timeframe,
        'stock.dateback': stock.dateback,
      })) as ProductStock[] | undefined
    }

    case 'rule':
    {
      const data = graphQlResponse as GQLSuccessItemResponse<RuleGqlData> | undefined
      return data?.searchResource?.items?.map((rule) => ({
        id: rule.id,
        'rule.id': rule.id,
        'rule.type': rule.type,
        'rule.label': rule.label,
        'rule.siteid': rule.siteid,
        'rule.provider': rule.provider,
        'rule.config': rule.config,
        'rule.status': rule.status,
      })) as RuleGql[] | undefined
    }

    default:
      return []
  }
}
