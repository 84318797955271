const ClockIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="10" cy="10" r="8.5" stroke="#575757" strokeWidth="0.8" />
    <line x1="9.84844" y1="5.7116" x2="9.84844" y2="10.0424" stroke="#575757" strokeWidth="0.9" strokeLinecap="round" />
    <line x1="9.83759" y1="9.97647" x2="12.9776" y2="14.1078" stroke="#575757" strokeWidth="0.9" strokeLinecap="round" />
  </svg>

)

export default ClockIcon
