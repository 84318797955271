import { Agent } from 'https'
import { captureException } from '@sentry/nextjs'

import {
  hasIncluded,
  hasLinks, hasRelationships,
  IGetResponseDocument,
  IResourceIdentifierObject,
  isGetSuccess,
  isSuccess,
  ISuccessResourceDocument,
} from './types/aimeosApi'
import accessCache from '../services/build-cache'
import { isProduction } from './environment'
import { clearCookie } from './cookies'
import { Methods } from './types/response'

export const cookies = (key: string): string | undefined => {
  if (typeof document !== 'undefined') {
    return Object.fromEntries(
      document
        .cookie
        .split('; ')
        .map((v) => v.split('=').map(decodeURIComponent)),
    )[key]
  }
  return undefined
}

const fetcher = async (
  url: string,
  fetchMethod?: Methods,
  data?: string | FormData,
  init?: RequestInit,
  saveCache: boolean = true,
) => {
  try {
    if (!isProduction) {
      // Clear duplicate shadowing cookie from top level domain if needed
      clearCookie('XSRF-TOKEN', '.droppe.com')
    }
    const csrf = cookies('XSRF-TOKEN')
    const headers = {
      Accept: 'application/json',
      ...((typeof data === 'string') && { 'Content-Type': 'application/json' }), // Needs to be multipart/form-data which cannot be set manually because of boundary
      ...(csrf && { 'X-XSRF-TOKEN': csrf }),
      ...init?.headers,
    }
    const method = fetchMethod || 'GET'
    if (method === 'GET') {
      const cache = await accessCache('build.cache', { scheduleTtl: false })
      const cached = await cache.get<any>(url)
      if (cached) {
        return new Response(JSON.stringify(cached))
      }
    }
    const resp = await fetch(url, {
      method,
      credentials: 'include',
      body: data,
      ...init,
      headers,
      ...((url.includes('https') && Agent) && { agent: new Agent({
        rejectUnauthorized: false,
      }) }),
    })

    if (!resp.ok) {
      captureException(new Error(`Fetch error: ${resp.status} ${resp.statusText}`), {
        tags: {
          url,
          method: fetchMethod || 'GET',
          status: resp.status,
        },
      })

      return resp
    }

    const body = await resp.json()

    if (method === 'GET' && saveCache && resp.status === 200 && body) {
      // Only cache successful requests with validity time 60s
      const cache = await accessCache('build.cache', { scheduleTtl: false })
      await cache.put<any>(url, body, 300000)
    }

    return new Response(JSON.stringify(body), { status: resp?.status })
  } catch (e) {
    captureException(e, {
      tags: {
        url,
        method: fetchMethod || 'GET',
      },
    })
    console.error('Error fetching from:', url, e)
    return new Response(JSON.stringify({}), { status: 500 })
  }
}

export const getJSONAPIPages = async (
  url: string,
  init?: RequestInit,
  skipRecursive: boolean = false,
): Promise<ISuccessResourceDocument> => {
  const res = await fetcher(url, 'GET', undefined, init)
  const resData: IGetResponseDocument = await res.json()

  if (isSuccess(resData) && hasLinks(resData)) {
    if (typeof resData.links?.next === 'string' && Array.isArray(resData.data) && !skipRecursive) {
      const nextPage = await getJSONAPIPages(resData.links.next, init)
      if (Array.isArray(nextPage.data)) {
        return {
          meta: resData.meta,
          links: resData.links,
          data: [
            ...resData.data,
            ...nextPage.data,
          ],
          included: [
            ...(hasIncluded(resData) ? resData.included || [] : []),
            ...(nextPage.included || []),
          ],
        }
      }
    }
    return resData
  }
  const { pathname } = new URL(url)
  console.error(`Could not fetch from XXXXXX/${pathname}`, resData)
  return {
    meta: resData.meta,
    data: [],
    links: resData.links,
    included: [],

  }
}

export const filterSingleProductData = (
  respData: IGetResponseDocument, productCode: string,
): IGetResponseDocument => {
  const returnData: IGetResponseDocument = {
    data: [],
    included: [],
    links: respData.links,
    meta: respData.meta,
  }
  try {
    if (respData && isGetSuccess(respData) && Array.isArray(respData.data)) {
      returnData.data = respData.data.filter((resource: any) => resource.attributes['product.code'] === productCode)
      const data = returnData.data[0]
      let neededIncludes: IResourceIdentifierObject[] = []
      if (hasRelationships(data) && data.relationships) {
        neededIncludes = Object.entries(data.relationships).flatMap(([, value]) => (
          value.data as IResourceIdentifierObject[]
        ))
      }
      if (hasIncluded(respData) && respData.included) {
        returnData.included = respData.included.filter((resource: any) => (
          neededIncludes.find((needed) => (
            needed.id.toString() === resource.id.toString()
            && needed.type === resource.type))))
      }
    }
  } catch (e) {
    captureException(e)
    console.error(e)
  }
  return returnData
}

export const SWRFetcher = (
  openInvalidSessionModal: () => void,
  pathname: string,
  shouldUseCache: boolean = true,
) => (resource: string, initStr?: string) => {
  // Global fetcher function, pass other fetcher to useSWR to override
  const init: RequestInit = JSON.parse(initStr || 'null')
  const csrf = cookies('XSRF-TOKEN')
  const headers = {
    ...init?.headers,
    ...(csrf && { 'X-XSRF-TOKEN': csrf }),
    Accept: 'application/json',
    ...(shouldUseCache ? {} : { 'Cache-Control': 'no-cache' }),
  }

  const initWithToken: RequestInit = {
    credentials: 'include',
    headers,
    ...init,
  }

  return fetch(resource, initWithToken).then(async (res) => {
    const isSupplerSidePage = pathname.includes('/manage') || pathname.includes('/profile')
    if (res.status === 401 && isSupplerSidePage) {
      localStorage.removeItem('user')
      openInvalidSessionModal()
    }

    return res.json()
  })
}

export async function strapiFetcher<T>(
  url: string,
): Promise<T> {
  try {
    const response = await fetch(url)

    if (!response.ok) {
      throw new Error(`Strapi API call failed with status: ${response.status}`)
    }

    const data: T = await response.json()
    return data
  } catch (error) {
    console.error('Error fetching from Strapi:', error)
    captureException(error)
    throw error
  }
}

export const sendDataToZapier = async (
  formData: FormData,
  handleSuccess: () => void,
  handleError: () => void,
  suggestions: boolean = false,
) => {
  const url = suggestions
    ? process.env.NEXT_PUBLIC_ZAPIER_API_URL_FOR_SUGGESTIONS ?? ''
    : process.env.NEXT_PUBLIC_ZAPIER_API_URL ?? ''

  try {
    const rawResponse = await fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
      body: formData,
    })
    const content = await rawResponse.json()

    if (content.status === 'success') {
      handleSuccess()
    } else {
      handleError()
    }
  } catch (error) {
    captureException(error)
    handleError()
  }
}

export default fetcher
