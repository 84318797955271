import { FC } from 'react'
import { Highlight, HitsProps, useHits } from 'react-instantsearch'
import { useRouter } from 'next/router'
import { FlexboxGrid, List } from 'rsuite'
import useTranslation from 'next-translate/useTranslation'

import { getCountryAndLocaleStrings } from '../../utils/locales'
import useUrls from '../../services/useUrls'
import AnchorLink from '../AnchorLink'
import Img from '../Img'
import { TypesenseSupplier } from '../../utils/types/search'

import styles from '../../styles/Search.module.less'

const SupplierHits: FC<HitsProps<TypesenseSupplier> & { closeModal: () => void }> = (props) => {
  const { closeModal, ...defaultProps } = props
  const { locale: countryAndLocale } = useRouter()
  const { locale } = getCountryAndLocaleStrings(countryAndLocale)
  const { urlT } = useUrls()
  const { t } = useTranslation('products')

  const {
    hits,
    results,
  } = useHits(defaultProps)

  const url = (code: string) => urlT(`/supplier/${code}`)

  return (
    <>
      <FlexboxGrid align="middle" justify="space-between">
        <FlexboxGrid.Item>
          <h5>{t('Suppliers')}</h5>
        </FlexboxGrid.Item>
        { results && (
          <FlexboxGrid.Item>
            {t('common:Showing {{ hits }} of {{ total }} results', { hits: results.hits.length, total: results.nbHits })}
          </FlexboxGrid.Item>
        ) }
      </FlexboxGrid>
      <List size="sm" className={styles['hits-list']}>
        { hits.map((hit) => (
          <List.Item key={hit.id}>
            <AnchorLink onClick={closeModal} href={url(hit.code)}>
              <FlexboxGrid align="middle" justify="start">
                <FlexboxGrid.Item className={styles['image-container']}>
                  <Img src={hit.image} layout="fill" />
                </FlexboxGrid.Item>
                <FlexboxGrid.Item>
                  <Highlight hit={hit} attribute="name" />
                  <br />
                  <h6>
                    {hit[`description_${locale ?? 'en'}`]?.slice(0, 50)}
                    ...
                  </h6>
                </FlexboxGrid.Item>
              </FlexboxGrid>
            </AnchorLink>
          </List.Item>
        )) }
      </List>
    </>
  )
}

export default SupplierHits
