import { createContext, FC, useReducer } from 'react'

import { GlobalTrackingObject, TrackingActionObject, TrackingActions, TrackingContextInterface } from '../../utils/types/analytics'

// Initial state and reducer
const globalTrackingObject: GlobalTrackingObject = {}

export const trackingReducer = (
  state: GlobalTrackingObject,
  action: TrackingActionObject,
): GlobalTrackingObject => {
  switch (action.type) {
    case TrackingActions.Add:
      return {
        ...state,
        [action.payload?.eventName || '']: action.payload?.eventParameters,
        util: action.payload?.util,
      }
    case TrackingActions.Remove:
      return {
        ...state,
        [action.payload?.eventName || '']: {},
      }
    case TrackingActions.Clear:
      return {}
    default:
      return state
  }
}

// Initialize context
export const TrackingContext = createContext<TrackingContextInterface>({
  state: globalTrackingObject,
  dispatch: () => null,
})

// Provider component
const TrackingProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(trackingReducer, globalTrackingObject)

  return (
    <TrackingContext.Provider value={{ state, dispatch }}>
      {children}
    </TrackingContext.Provider>
  )
}

export default TrackingProvider
