import { FC } from 'react'
import { FlexboxGrid } from 'rsuite'
import SettingHorizontalIcon from '@rsuite/icons/SettingHorizontal'
import ListIcon from '@rsuite/icons/List'
import ExitIcon from '@rsuite/icons/Exit'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'

import OrderListIcon from '../../../Icons/SavedProducts/OrderListIcon'
import HeartIcon from '../../../Icons/SavedProducts/HeartIcon'
import AnchorLink from '../../../AnchorLink'
import useUrls from '../../../../services/useUrls'
import { useAuth } from '../../../../services/useAuth'

import styles from '../../../../styles/ProfileIconPicker.module.less'

const ProfileIconPickerContent: FC = () => {
  const { t } = useTranslation('buyerProfile')
  const { urlT } = useUrls()
  const { user } = useAuth()

  const { route } = useRouter()

  const NavItems = [{
    label: 'My account',
    route: '/my-account',
    icon: SettingHorizontalIcon,
  },
  {
    label: 'Saved products',
    route: '/my-products',
    icon: HeartIcon,
  },
  {
    label: 'My catalogs',
    route: '/my-catalogs',
    icon: OrderListIcon,
  },
  {
    label: 'Order history',
    route: '/my-orders',
    icon: ListIcon,
  },
  {
    label: 'Log out',
    route: '/logout',
    icon: ExitIcon,
    classes: styles['sidenav-logout'],
  }]

  return (
    <div>
      {NavItems
        .filter((item) => !!user || (!user && item.label !== 'Log out'))
        .map((item) => (
          <AnchorLink
            key={item.label}
            href={urlT(item.route)}
            className={`${item.classes} ${
              route.includes(item.route) ? styles.active : ''
            }`}
          >
            <FlexboxGrid align="middle" className="margin-top-spacer-double">
              <item.icon variant={route.includes(item.route) ? 'primary' : 'dark'} />
              <FlexboxGrid.Item className={styles.label}>{t(item.label)}</FlexboxGrid.Item>
            </FlexboxGrid>
          </AnchorLink>
        ))}
    </div>
  )
}

export default ProfileIconPickerContent
