import React, { FC } from 'react'

const UnsupportedIcon: FC = () => (
  <svg width="200" height="200" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M99.9996 199.999C155.228 199.999 199.999 155.228 199.999 99.9996C199.999 44.7714 155.228 0 99.9996 0C44.7714 0 0 44.7714 0 99.9996C0 155.228 44.7714 199.999 99.9996 199.999Z" fill="url(#paint0_linear_31382_25144)" />
    <path fillRule="evenodd" clipRule="evenodd" d="M159.998 180.01C143.284 192.564 122.51 200.003 99.9977 200.003C77.4857 200.003 56.7112 192.564 39.998 180.011V53.3359C39.998 51.1268 41.7889 49.3359 43.998 49.3359H155.998C158.207 49.3359 159.998 51.1268 159.998 53.3359V180.01Z" fill="white" />
    <path d="M40 53.3281C40 51.119 41.7909 49.3281 44 49.3281H156C158.209 49.3281 160 51.119 160 53.3281V86.6613H40V53.3281Z" fill="#054C2E" />
    <path opacity="0.9" d="M92.0005 56H57.334C55.1248 56 53.334 57.7909 53.334 60C53.334 62.2091 55.1248 64 57.334 64H92.0005C94.2096 64 96.0005 62.2091 96.0005 60C96.0005 57.7909 94.2096 56 92.0005 56Z" fill="#B1F946" />
    <path d="M116 72H57.334C55.1248 72 53.334 73.7909 53.334 76C53.334 78.2091 55.1248 80 57.334 80H116C118.21 80 120 78.2091 120 76C120 73.7909 118.21 72 116 72Z" fill="#B1F946" />
    <path d="M119.999 131.333C119.999 120.656 111.343 112 100.665 112C89.9878 112 81.332 120.656 81.332 131.333C81.332 142.011 89.9878 150.667 100.665 150.667C111.343 150.667 119.999 142.011 119.999 131.333Z" fill="#054C2E" />
    <path d="M94.1464 123.939L94.233 124.011L100.666 130.444L107.099 124.011C107.343 123.767 107.739 123.767 107.983 124.011C108.2 124.228 108.224 124.565 108.055 124.809L107.983 124.895L101.55 131.328L107.983 137.761C108.227 138.005 108.227 138.401 107.983 138.645C107.766 138.862 107.429 138.886 107.186 138.717L107.099 138.645L100.666 132.212L94.233 138.645C93.9889 138.889 93.5931 138.889 93.3491 138.645C93.1321 138.428 93.108 138.091 93.2768 137.848L93.3491 137.761L99.7822 131.328L93.3491 124.895C93.105 124.651 93.105 124.255 93.3491 124.011C93.566 123.794 93.9028 123.77 94.1464 123.939Z" fill="white" stroke="white" />
    <defs>
      <linearGradient id="paint0_linear_31382_25144" x1="99.9996" y1="0" x2="99.9996" y2="199.999" gradientUnits="userSpaceOnUse">
        <stop stopColor="#B1F946" stopOpacity="0.19" />
        <stop offset="1" stopColor="#B1F946" stopOpacity="0.39" />
      </linearGradient>
    </defs>
  </svg>

)

export default UnsupportedIcon
