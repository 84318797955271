import { IconProps } from '@rsuite/icons/lib/Icon'
import { FC } from 'react'

import { BLACK_COLOR } from '../../utils/constants'

const ProfileIcon: FC<IconProps> = ({ className, onClick }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="23" height="32" viewBox="0 0 23 27" fill="none" className={className} onClick={onClick}>
    <path d="M20.418 25.5971C20.9703 25.5971 21.4224 25.1486 21.3756 24.5983C21.284 23.5196 21.0442 22.4602 20.6629 21.4558C20.1643 20.1429 19.4336 18.9499 18.5125 17.945C17.5913 16.9401 16.4978 16.143 15.2942 15.5991C14.0907 15.0553 12.8007 14.7754 11.498 14.7754C10.1953 14.7754 8.9054 15.0553 7.70186 15.5991C6.49832 16.143 5.40475 16.9401 4.4836 17.945C3.56245 18.9499 2.83176 20.1429 2.33323 21.4558C1.9519 22.4602 1.71212 23.5196 1.62047 24.5983C1.57371 25.1486 2.02584 25.5971 2.57812 25.5971L20.418 25.5971Z" stroke={BLACK_COLOR} strokeWidth="2" />
    <circle cx="11.499" cy="6.3623" r="4.95996" stroke={BLACK_COLOR} strokeWidth="2" />
  </svg>
)

export default ProfileIcon
