/* eslint-disable import/no-cycle */
import { Translate } from 'next-translate'

import { Catalog, Product, SalesUnit } from '../utils/types/Product'
import { getProductPriceObject } from './resources/price'
import { formatGenericIntegerNumber } from '../utils/math'

export const getPerBoxItems = (product?: Product) => (
  Number(
    product?.['product/property']
      .find((property) => property['product.property.type'] === 'per-box')
      ?.['product.property.value']
    ?? 0,
  )
)

export const getPerCartonItems = (product: Product) => (
  Number(
    product['product/property']
      .find((property) => property['product.property.type'] === 'per-carton')
      ?.['product.property.value']
    ?? 0,
  )
)

export const isPairCatalog = (catalog: Partial<Catalog> & Pick<Catalog, 'catalog.config'>): boolean => !Array.isArray(
  catalog?.['catalog.config'],
) && catalog?.['catalog.config']?.referenceUnit === 'pair'

export const isHundredPieceCatalog = (catalog: Partial<Catalog> & Pick<Catalog, 'catalog.config'>) => !Array.isArray(
  catalog?.['catalog.config'],
) && catalog?.['catalog.config']?.referenceUnit === '100 pcs'

export const isPairProduct = (product: Product): boolean => product
  .catalog?.some((cat) => isPairCatalog(cat))

export const isHundredPieceProduct = (product: Product): boolean => product
  .catalog?.some((cat) => isHundredPieceCatalog(cat))

export const getProductSalesCount = (
  product?: Product,
  t: Translate | ((s: string) => string) = (s: string) => s,
) => {
  if (!product) return ''

  const perBoxItems = getPerBoxItems(product)
  const unit = isPairProduct(product) ? 'pair' : 'pc'
  return perBoxItems ? ` ${perBoxItems} ${t(`products:${unit}`, { count: perBoxItems })}` : ''
}

export const getPricePerPieceUnit = (product: Product) => {
  if (isPairProduct(product)) return 'pair'
  if (isHundredPieceProduct(product)) return '100 pcs'
  return 'piece'
}

export const getPricePerPieceUnitCatalog = (catalog: Partial<Catalog> & Pick<Catalog, 'catalog.config'>) => {
  if (isPairCatalog(catalog)) return 'pair'
  if (isHundredPieceCatalog(catalog)) return '100 pcs'
  return 'piece'
}

export const getSalesUnit = (product: Product): SalesUnit => {
  const salesUnit = product['product.sales-unit'] ?? product.attribute.find(
    (attribute) => attribute['attribute.type'] === 'sales-unit',
  )?.['attribute.code'] as SalesUnit

  if (salesUnit) {
    return (isPairProduct(product) && salesUnit === 'piece') ? 'pair' : salesUnit
  }

  // Rest of the code is just a fallback which might never be needed
  if (getPerCartonItems(product)) return 'carton'
  if (getPerBoxItems(product)) return 'package'
  return isPairProduct(product) ? 'pair' : 'piece'
}

export const getTotalPieceCount = (product: Product): number => {
  const salesUnit = getSalesUnit(product)

  switch (salesUnit) {
    case 'carton':
      return (getPerBoxItems(product) || 1) * (getPerCartonItems(product) || 1)

    case 'package':
      return (getPerBoxItems(product) || 1)

    default:
      return 1
  }
}

export const getReferencePrice = (product: Product, productPrice?: number | string) => {
  const totalPieceCount = getTotalPieceCount(product)
  const count = getPricePerPieceUnit(product) === '100 pcs' ? totalPieceCount / 100 : totalPieceCount
  const defaultProductPrice = getProductPriceObject(product)?.['price.value'] ?? NaN
  return Number(productPrice ?? defaultProductPrice) / count
}

export const getSmallestBarcodeUnit = (product: Product) => {
  const salesUnit = getSalesUnit(product)
  if (salesUnit === 'carton' || salesUnit === 'package') return 'package'
  return isPairProduct(product) ? 'pair' : 'piece'
}

export const getSmallestBarcodeItemsMoq = (
  product: Product,
  t: Translate | ((s: string) => string) = (s: string) => s,
  salesUnitMoq: number,
) => {
  const salesUnit = getSalesUnit(product)
  let moq = salesUnitMoq
  if (salesUnit === 'carton') {
    moq = salesUnitMoq * (getPerCartonItems(product) || 1)
  }

  return `${formatGenericIntegerNumber(moq)} ${t(getSmallestBarcodeUnit(product), { count: moq })}`
}

export const getSalesUnitDescription = (
  product: Product,
  t: Translate | ((s: string) => string) = (s: string) => s,
  salesUnit: 'carton' | 'package' | 'pair' | 'piece',
) => {
  const totalPieceCount = (getPerBoxItems(product) || 1) * (getPerCartonItems(product) || 1)
  const perPackageItems = getPerBoxItems(product)
  const unit = isPairProduct(product) ? 'pair' : 'pc'
  if (salesUnit === 'carton') {
    return t('products:A carton contains {{ perCartonItems }} packages ({{ totalPieceCount }} {{ unit }})',
      {
        perCartonItems: formatGenericIntegerNumber(getPerCartonItems(product)),
        totalPieceCount: formatGenericIntegerNumber(totalPieceCount),
        unit: t(unit, { count: totalPieceCount }),
      })
  }
  if (salesUnit === 'package') {
    return t('products:A package contains {{ perPackageItems }} {{ unit }}',
      {
        perPackageItems: formatGenericIntegerNumber(perPackageItems),
        unit: t(`products:${unit}`, { count: perPackageItems }),
      })
  }
  return salesUnit === 'piece'
    ? `${t('products:Sold in units of one piece')}`
    : `${t('products:Sold in units of one pair')}`
}

export const getTotalPieceCountString = (
  product: Product,
  t: Translate | ((s: string) => string) = (s: string) => s,
  salesUnit: 'carton' | 'package' | 'pair' | 'piece',
  orderedQuantity?: number,
): string => {
  const totalPieceCount = (getPerBoxItems(product) || 1) * (getPerCartonItems(product) || 1)
  const perPackageItems = getPerBoxItems(product)
  const unit = isPairProduct(product) ? 'pair' : 'pc'

  let quantity = orderedQuantity || 1
  let countText = t(unit, { count: totalPieceCount })

  if (salesUnit === 'carton') {
    quantity *= totalPieceCount
  } else if (salesUnit === 'package') {
    quantity *= perPackageItems
    countText = t(unit, { count: perPackageItems })
  } else if (salesUnit === 'piece') {
    countText = t(unit, { count: 1 })
  }

  return `(${formatGenericIntegerNumber(quantity)} ${countText})`
}
