import { FC } from 'react'
import { Button, Divider, FlexboxGrid, Nav } from 'rsuite'
import useTranslation from 'next-translate/useTranslation'
import dynamic from 'next/dynamic'
import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { useAuth } from '../../../services/useAuth'
import useUrls from '../../../services/useUrls'
import useContactModal from '../../Modals/hooks/useContactModal'
import DroppeLogo from '../../Icons/DroppeLogo'
import MobileCountryPicker from './CountryPicker/MobileCountryPicker'
import MobileSearch from './Search/MobileSearch'
import MobileCategoriesMenu from './MobileCategoryTree/MobileCategoriesMenu'
import AnchorLink from '../../AnchorLink'
import useInternalTraffic from '../../hooks/useInternalTraffic'
import ProfileIcon from '../../Icons/ProfileIcon'
import useLoginModal from '../../../services/useLoginModal'
import HeartIcon from '../../Icons/SavedProducts/HeartIcon'
import useIsOnMobile from '../../../services/useIsOnMobile'
import {
  LOGIN_MODAL_TRIGGERED_PLACE_KEY,
  LoginModalTriggeredPlace,
  ScreenSize,
} from '../../../utils/constants'
import SignInPicker from './SignInPicker/SignInPicker'

import navStyles from '../../../styles/Navigation.module.less'

const InternalTrafficButton = dynamic(() => import('../../InternalTrafficButton'))

const MobileNavigation: FC = () => {
  const { user } = useAuth()
  const { urlT, pushT } = useUrls()
  const { t } = useTranslation('common')

  const { isInternalTraffic } = useInternalTraffic()
  const isOnMobile = useIsOnMobile(ScreenSize.sm)

  const openContactModal = useContactModal('main_header_modal', 'main_header_contact_modal')
  const { openLoginModal } = useLoginModal()

  const handleProfileIconClick = (url: string, icon: 'profile' | 'heart') => {
    if (user) {
      pushT(url)
      return
    }

    // Using session storage to handle redirection logic in LoginModal
    if (icon === 'heart') {
      sessionStorage.setItem(LOGIN_MODAL_TRIGGERED_PLACE_KEY, LoginModalTriggeredPlace.headerHeart)
    }

    openLoginModal('main_header')
  }

  const saveProductFeatureFlag = useFeatureIsOn('product-card-heart-icon')
  const profileIconFeatureFlag = useFeatureIsOn('header-profile-icon')

  return (
    <div className="max-width-lg">
      <FlexboxGrid className={navStyles['header-nav-content']}>
        <div className={`${navStyles['header-brand-container-mobile']}`}>
          <div className={navStyles['header-top']}>
            <AnchorLink href={urlT('/')}>
              <DroppeLogo size={`${isOnMobile ? 'sd' : 'lm'}`} />
            </AnchorLink>
            <div className={navStyles['country-contactus-btn']}>
              {isOnMobile && (
              <div className={navStyles['mobile-country-picker']}>
                <MobileCountryPicker />
              </div>
              )}
              {!isOnMobile && <SignInPicker handleProfileIconClick={handleProfileIconClick} />}
              <Button
                appearance="primary"
                className="margin-left-spacer-double"
                onClick={(e) => {
                  e.preventDefault()
                  openContactModal()
                }}
              >
                {t('Contact us')}
              </Button>
            </div>
          </div>
        </div>

      </FlexboxGrid>
      <Divider className="margin-top-spacer margin-bottom-spacer" />
      <FlexboxGrid
        align="middle"
        className={navStyles['mobile-nav-grid']}
      >
        <div className={navStyles['mobile-nav-icons']}>
          <div className={navStyles['mobile-category-menu']}>
            <MobileCategoriesMenu />
          </div>
          <div className={`${navStyles['nav-icons-alignment']}`}>
            <div
              className={`
              ${navStyles['nav-icons-group1']}
              ${isOnMobile ? navStyles['nav-icons-width'] : navStyles['nav-icons-width-desktop']}`}
            >

              <div className={navStyles['internal-traffic']}>
                {isInternalTraffic && <InternalTrafficButton />}
              </div>

              {!isOnMobile && (
                <MobileCountryPicker />
              )}

              <div className={navStyles['mobile-search']}>
                <MobileSearch />
              </div>

              {saveProductFeatureFlag && (
                <Nav className={navStyles['heart-icon-nav']}>
                  <HeartIcon
                    className={`${navStyles['mobile-select-product-icon']}
                              ${navStyles['heart-icon']}
                              cursor-pointer`}
                    onClick={() => handleProfileIconClick('/my-products', 'heart')}
                    variant="dark"
                  />
                </Nav>
              )}

              {profileIconFeatureFlag && (
                <div className={navStyles['profile-icon']}>
                  <ProfileIcon
                    className="cursor-pointer flex-shrink-col-static"
                    onClick={() => handleProfileIconClick('/my-account', 'profile')}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </FlexboxGrid>
    </div>
  )
}

export default MobileNavigation
