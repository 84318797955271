import TagManager from 'react-gtm-module'

import { getModifiedCartTotalDiscount, getSupplierGroupByProducts, getSupplierMovData } from '../../lib/cart'
import { formatCategoriesForAnalytics } from '../../lib/category'
import { roundToTwoDecimals } from '../math'
import { AnalyticsProduct, Product } from '../types/Product'
import { isProductInclude } from '../types/guards/Order'
import { SingleOrderResponse, WithSuppliers } from '../types/Order'
import { UserIdResponse } from '../../pages/api/generate-user-id'
import { ActiveCountryId, CountryId, GENERATE_USER_ID_URL } from '../constants'
import { getBrandName, getProductName } from '../../lib/text'
import { getProductPrice } from '../../lib/resources/price'
import { getCurrencyCode } from '../locales'

interface GetEcomItemsCartParams {
  cartData: WithSuppliers<SingleOrderResponse>
  country?: ActiveCountryId
}

export const getCartEcomEventItems = (params: GetEcomItemsCartParams): AnalyticsProduct[] => {
  const { cartData, country } = params
  const { included, data } = cartData

  // If no products in cart, return empty array
  if (included?.filter(isProductInclude).length === 0) return []

  // If included is undefined return empty array, else return items list.
  return included
    ? included.filter(isProductInclude).map(
      (item): AnalyticsProduct => {
        const { attributes, product } = item
        const variantObj = attributes?.attribute?.find((attr: any) => attr['order.product.attribute.type'] === 'variant')
        const brand = product ? getBrandName(product) : ''
        const supplier = product?.supplier[0]['supplier.label'] || attributes['order.product.suppliername']
        const variant = variantObj ? variantObj['order.product.attribute.name'] : attributes['order.product.name']
        const orderedCategories = product ? formatCategoriesForAnalytics(product) : {}

        const supplierCode = product?.supplier[0]['supplier.code'] || ''
        const modifiedCart = getSupplierGroupByProducts(cartData)
        const { isUnderMov } = country
          ? getSupplierMovData(supplierCode, country, modifiedCart)
          : { isUnderMov: undefined }

        return {
          item_id: (product && product['product.code']) || '',
          item_name: attributes['order.product.name'],
          price: roundToTwoDecimals(attributes['order.product.price']),
          currency: data?.attributes['order.currencyid'],
          item_brand: brand ?? supplier,
          supplier,
          item_variant: variant,
          quantity: attributes['order.product.quantity'],
          is_under_mov: isUnderMov,
          ...orderedCategories,
        }
      },
    )
    : []
}

interface GetAnalyticsProductItemParams {
  product: Product
  country: CountryId
}

export const getAnalyticsProduct = (
  params: GetAnalyticsProductItemParams,
): AnalyticsProduct => {
  const { product, country } = params

  const categories = formatCategoriesForAnalytics(product)
  const brand = getBrandName(product)
  const itemName = getProductName(product)
  const currency = getCurrencyCode(country)
  const price = getProductPrice(product, currency)

  return {
    affiliation: 'Droppe Marketplace',
    item_id: product['product.code'],
    item_name: itemName || product['product.label'],
    price,
    currency,
    item_brand: brand || `${product.supplier[0]['supplier.label']} (fallback, supplier name)`,
    supplier: product.supplier[0]['supplier.label'],
    ...categories,
  }
}

export const getEcomEventItemsFromProducts = (
  products: Product[],
  country: CountryId,
): AnalyticsProduct[] => products
  .map((product: Product) => getAnalyticsProduct({ product, country }))

export const getCartTotalValue = (cartResponse: SingleOrderResponse) => roundToTwoDecimals(Number(cartResponse.data?.attributes['order.price']) + Number(cartResponse.data?.attributes['order.taxvalue']) + Number(cartResponse.data?.attributes['order.costs']))

export const getCartTotalDiscount = (cart: WithSuppliers<SingleOrderResponse>) => {
  const modifiedCart = getSupplierGroupByProducts(cart)
  return roundToTwoDecimals(getModifiedCartTotalDiscount(modifiedCart))
}

export const emptyEcomDataLayer = () => TagManager.dataLayer({ dataLayer: { ecommerce: null } })

export const getHashedUserId = async (email: string): Promise<UserIdResponse> => {
  try {
    const response = await fetch(GENERATE_USER_ID_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    })

    if (!response.ok) {
      const errorData = await response.json()
      return { ...errorData, error: `Server responded with status code ${response.status}` }
    }

    const data: UserIdResponse = await response.json()
    return data
  } catch (error) {
    console.error('Network error:', error)
    return { error: 'Network error' }
  }
}
