import { FC, RefObject, useEffect, useRef } from 'react'
import Link from 'next/link'
import { Badge, Popover, Whisper } from 'rsuite'
import { OverlayTriggerInstance } from 'rsuite/esm/Picker'
import { useRouter } from 'next/router'

import HeaderCartPopup from '../../Popups/Cart/HeaderCartPopup'
import useUrls from '../../../services/useUrls'
import CartIcon from '../../Icons/CartIcon'
import { useCart } from '../../Cart/CartProvider'
import { selectCartProductsUnique, selectTotalCartQuantity } from '../../Cart/selectors'
import { ALERT_DURATION } from '../../../utils/constants'

import cartPopupStyles from '../../../styles/CartPopup.module.less'
import styles from '../../../styles/CartIcon.module.less'

interface CartNavProps {
  headerRef: RefObject<HTMLDivElement>
  className?: string
}

const CartNav: FC<CartNavProps> = ({ headerRef, className }) => {
  const whisperRef = useRef<OverlayTriggerInstance>(null)
  const { urlT } = useUrls()
  const { asPath, query } = useRouter()
  const isOnCartPage = asPath === urlT('/cart')
  const { cartState: { cart } } = useCart()

  const { shareParams } = query
  const products = cart ? selectCartProductsUnique(cart) : []
  const productCount = products.length
  const isPopupOpen = useRef(false)

  const quantityCount = cart ? selectTotalCartQuantity(cart) : 0

  // Value -1 means data is not loaded yet
  const currentQuanityCount = useRef(-1)

  useEffect(() => {
    // Don't do anything if data is not loaded
    if (!cart?.included) {
      return () => {}
    }

    // Don't do anything on initial load
    if (currentQuanityCount.current === -1) {
      currentQuanityCount.current = quantityCount
      return () => {}
    }

    const hasTotalQuantityCountChanged = currentQuanityCount.current !== quantityCount

    if (whisperRef.current
      && !isOnCartPage
      && !shareParams
      && quantityCount
      && hasTotalQuantityCountChanged
    ) {
      currentQuanityCount.current = quantityCount
      whisperRef.current?.open()
      isPopupOpen.current = true
    }

    const timeoutId = setTimeout(() => {
      if (isPopupOpen.current) {
        whisperRef.current?.close()
        isPopupOpen.current = false
      }
    }, ALERT_DURATION)

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [products, isOnCartPage, quantityCount])

  return (
    <div data-testid="cart-icon" className={`${styles['cart-icon-container']} ${className}`}>
      <Link href={urlT('/cart')}>
        <a>
          <Whisper
            ref={whisperRef}
            container={headerRef?.current ? headerRef.current : undefined}
            placement="bottomEnd"
            trigger={productCount > 0 ? 'hover' : 'none'}
            speaker={(
              <Popover
                className={`${cartPopupStyles['cart-popup']} ${cartPopupStyles['header-cart-popup']}`}
                arrow={false}
              >
                <HeaderCartPopup
                  cart={cart}
                  totalProductsInCart={productCount}
                  products={products}
                />
              </Popover>
            )}
            enterable
          >
            <div>
              <Badge
                className={`${styles.counter} ${productCount ? styles['hover-style'] : ''}`}
                content={productCount < 1 || shareParams ? false : productCount}
              >
                <CartIcon />
              </Badge>
            </div>
          </Whisper>
        </a>
      </Link>
    </div>
  )
}

export default CartNav
