import { forwardRef, useImperativeHandle, useRef } from 'react'

import DroppeLogo from '../../Icons/DroppeLogo'
import CountryPicker from './CountryPicker/CountryPicker'
import useIsOnMobile from '../../../services/useIsOnMobile'
import { ScreenSize } from '../../../utils/constants'
import AnchorLink from '../../AnchorLink'

import styles from '../../../styles/SimpleHeader.module.less'

const SimpleHeader = forwardRef<HTMLElement>((_, ref) => {
  const countryPickerRef = useRef<HTMLDivElement>(null)
  const headerRef = useRef<HTMLDivElement>(null)
  const isOnMobile = useIsOnMobile(ScreenSize.md)

  useImperativeHandle(ref, () => headerRef.current!, [headerRef])

  return (
    <div className={`${styles.header} ${isOnMobile ? styles['mobile-header'] : ''}`}>
      <AnchorLink href="/">
        <DroppeLogo />
      </AnchorLink>
      <CountryPicker topHeaderRef={countryPickerRef} />
    </div>
  )
})

export default SimpleHeader
