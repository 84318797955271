import Link from 'next/link'
import React, { AnchorHTMLAttributes, FC } from 'react'

interface AnchorLinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  href: string
  shallow?: boolean
  scroll?: boolean
}

const AnchorLink: FC<AnchorLinkProps> = (props) => {
  const { href, children, scroll, shallow, className, ...rest } = props

  return (
    <Link href={href} shallow={shallow} scroll={scroll}>
      <a
        {...rest}
        className={className}
      >
        {children}
      </a>
    </Link>
  )
}

export default AnchorLink
