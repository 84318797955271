import { FC, RefObject } from 'react'
import { Container, Popover, Whisper } from 'rsuite'

import ProfileIcon from '../../../Icons/ProfileIcon'
import { HOVER_DELAY } from '../../../../utils/constants'
import ProfileIconPickerContent from './ProfileIconPickerContent'

import styles from '../../../../styles/ProfileIconPicker.module.less'

interface ProfileIconPickerProps {
  handleProfileIconClick: (url: string, icon: 'profile' | 'heart') => void
  topHeaderRef?: RefObject<HTMLDivElement>
}

const ProfileIconPicker: FC<ProfileIconPickerProps> = (props) => {
  const { handleProfileIconClick, topHeaderRef } = props

  return (
    <Whisper
      trigger="hover"
      enterable
      placement="bottomEnd"
      container={topHeaderRef ? topHeaderRef.current || undefined : undefined}
      delayOpen={HOVER_DELAY}
      speaker={(
        <Popover className={styles['picker-popover']}>
          <ProfileIconPickerContent />
        </Popover>
        )}
    >
      <Container>
        <ProfileIcon
          className={`cursor-pointer flex-shrink-col-static ${styles['profile-icon']}`}
          onClick={() => handleProfileIconClick('/my-account', 'profile')}
        />
      </Container>
    </Whisper>
  )
}

export default ProfileIconPicker
