import { useEffect, useMemo, useRef, useState } from 'react'
import { FileType } from 'rsuite/esm/Uploader'
import omit from 'lodash/omit'
import { UploaderInstance } from 'rsuite/esm/Uploader/Uploader'
import { captureException } from '@sentry/nextjs'
import { useRouter } from 'next/router'

import { useAuth } from '../../services/useAuth'
import { useAdminApi } from '../../services/useApi'
import { getMediumPreview } from '../../utils/images'
import { Customer, DeliveryService, PartialSupplier, Supplier, SupplierAddress, SupplierList } from '../../utils/types/Product'
import { InputFieldChangeFunction, SupplierProfileData } from '../../utils/types/Profile'
import { isSupplier } from '../../lib/supplier'
import { ResourceName } from '../../utils/types/Resource'
import { getCountryAndLocaleStrings } from '../../utils/locales'

const customerResourceTag: ResourceName = 'customer'
const supplierResourceTag: ResourceName = 'supplier'

const useSupplierProfileData = () => {
  const { user } = useAuth()
  const { locale: countryAndLocale } = useRouter()
  const { country } = getCountryAndLocaleStrings(countryAndLocale)

  const {
    getAttributes,
    getResource,
    createResource,
    deleteResource,
    updateResource,
    getSingleResource,
  } = useAdminApi()

  const [isSaving, setIsSaving] = useState(false)
  const [fetchedSupId, setSupplierId] = useState(-1)
  const [imageData, setImageData] = useState<FileType | null>(null)
  const [fileInfo, setFileInfo] = useState<string | null>(null)
  const [isUploadingImage, setIsUploadingImage] = useState(false)
  const [isDirty, setDirty] = useState(false)
  const [supplierData, setSupplierData] = useState<SupplierProfileData>({
    accountSettingsData: {
      firstname: user?.firstname || '',
      lastname: user?.lastname || '',
      title: user?.title || '',
      telephone: user?.telephone || '',
      address1: user?.address1 || '',
      address2: user?.address2 || '',
      address3: user?.address3 || '',
      postal: user?.postal || '',
      city: user?.city || '',
      company: user?.company || '',
      countryid: user?.countryid || '',
    },
    supplierProfileData: {
      id: '',
      'supplier.label': user?.company || '',
      text: [],
      'supplier/address': [],
    },
  })

  const uploaderRef = useRef<UploaderInstance | null>(null)

  const isValidSupplier = user && isSupplier(user)

  const query = isValidSupplier ? `include=media,text,supplier/address&filter[%3D%3D][supplier.siteid]=${user.siteid.toString()}` : ''
  const {
    data: supplierInfo,
    isLoading: isSupplierInfoLoading,
  } = getResource<Supplier>(isValidSupplier ? supplierResourceTag : null, query)

  const supplierId = supplierInfo.length > 0 ? Number(supplierInfo[0].id) : fetchedSupId ?? -1

  const { data: mediaLinks } = getResource<SupplierList>(
    supplierId !== -1 ? 'supplier/lists' : null, // Don't fetch if we don't have a supplierId yet
    `filter[%3D%3D][supplier.lists.parentid]=${supplierId}`,
  )

  const serviceQuery = `include=price&filter[%3D%3D][service.code]=${supplierInfo[0]?.['supplier.code']}`
  const {
    data: deliveryData,
    isLoading: isDeliveryServiceLoading,
  } = getSingleResource<DeliveryService>(supplierInfo[0]?.id ? 'service' : null, serviceQuery)

  useEffect(() => {
    // Fill the data when the user data has arrived
    if (user?.id) {
      setSupplierData((prevData) => ({ ...prevData,
        accountSettingsData: {
          firstname: user?.firstname || '',
          lastname: user?.lastname || '',
          title: user?.title || '',
          telephone: user?.telephone || '',
          address1: user?.address1 || '',
          address2: user?.address2 || '',
          address3: user?.address3 || '',
          postal: user?.postal || '',
          city: user?.city || '',
          company: user?.company || '',
          countryid: user?.countryid || '',
        } }))
    }
  }, [user?.id])

  useEffect(() => {
    // If user data is available but no supplier data is assigned, create one for the user
    if (user?.id && !supplierInfo.length && !isSupplierInfoLoading && supplierId !== -1) {
      const saveAndFetch = async () => {
        const [mainResource] = await createResource<Supplier, PartialSupplier>(
          customerResourceTag,
          { ...supplierData.supplierProfileData, id: user.id.toString(), 'supplier.id': '' },
          '',
        )
        if (Array.isArray(mainResource) && mainResource.length !== 0) {
          setSupplierId(Number(mainResource[0]['supplier.id']) ?? -1)
        }
      }
      saveAndFetch()
    }
  }, [isSupplierInfoLoading, user?.id, supplierInfo?.length, supplierId])

  useEffect(() => {
    const countryId = country.toLowerCase()
    const addressWithCountry = supplierInfo?.[0]?.['supplier/address']?.map((address) => ({
      ...address,
      'supplier.address.countryid': address['supplier.address.countryid'] || countryId,
    })) as SupplierAddress[] | undefined

    if (supplierInfo.length > 0 && !isSupplierInfoLoading) {
      setSupplierData((prevData) => ({ ...prevData,
        supplierProfileData: {
          'supplier.label': supplierInfo[0]['supplier.label'] || '',
          text: supplierInfo[0].text ?? [],
          'supplier/address': addressWithCountry ?? [],
        } }))
      setSupplierId(Number(supplierInfo[0].id) ?? -1)

      const { media } = supplierInfo[0]
      if (media && media.length > 0) {
        setFileInfo(getMediumPreview(media[0]))
      }
    }
  }, [supplierInfo.length, isSupplierInfoLoading])

  useEffect(() => {
    getAttributes()
  }, [])

  const deleteOldImage = async () => {
    if (supplierInfo.length > 0) {
      const { media } = supplierInfo[0]

      if (media && media.length > 0) {
        const old = media[0]
        const oldListItem = mediaLinks.find((listItem) => listItem['supplier.lists.refid'] === old.id)
        if (oldListItem) await deleteResource('supplier/lists', `&id=${oldListItem.id}`)
        await deleteResource('media', `&id=${old.id}`)
      }
    }
  }

  const uploadImage = async () => {
    if (!imageData) return

    setIsUploadingImage(true)
    try {
      await deleteOldImage()
    } catch (error) {
      captureException(error)
      console.error(error)
    } finally {
      setIsUploadingImage(false)
    }
  }

  const handleFormInputChange: InputFieldChangeFunction = (value, fieldKey, dataKey) => {
    if (dataKey in supplierData) {
      setSupplierData((prevData) => ({ ...prevData,
        [dataKey]: {
          ...prevData[dataKey],
          [fieldKey]: value,
        } }
      ))
      setDirty(true)
    } else {
      console.error('Invalid key for state update', { dataKey, fieldKey })
    }
  }
  // A small util function to create a new object with all the valid fields only
  const filterInvalidKeys = <T extends 'accountSettingsData' | 'supplierProfileData'>(data: SupplierProfileData[T]) => {
    const invalidKeys = Object.keys(data).filter(
      (key) => data[key as keyof typeof data] === undefined,
    )

    // Create a new object with all the valid fields only
    return omit(data, invalidKeys)
  }

  const updateSupplierProfile = async () => {
    const { supplierProfileData } = supplierData
    const updatedSupplierDetailsData = filterInvalidKeys<'supplierProfileData'>(supplierProfileData)
    const addressObj = supplierProfileData['supplier/address']
    const addrId = addressObj?.[0]?.id

    if (addressObj && addrId) {
      await updateResource<SupplierAddress, Partial<SupplierAddress>>(
        'supplier/address', addressObj[0], { id: addrId },
      )
    }

    const params = {
      id: supplierId.toString(),
    }

    await updateResource(supplierResourceTag, updatedSupplierDetailsData, params)

    // Handle image upload
    if (fileInfo && !isUploadingImage && uploaderRef.current) {
      await uploadImage()
      uploaderRef.current.start() // Fire the API request for the image upload
    }
  }

  const updateAccountSettings = async () => {
    const { accountSettingsData } = supplierData

    const updatedCustomerData = filterInvalidKeys<'accountSettingsData'>(accountSettingsData)

    const params = {
      id: user?.id.toString() || '',
    }
    return updateResource(customerResourceTag, updatedCustomerData as Partial<Customer>, params)
  }

  return useMemo(() => ({
    supplierData,
    supplierInfo,
    deliveryData,
    isSaving,
    fetchedSupId,
    fileInfo,
    isUploadingImage,
    isDirty,
    isSupplierInfoLoading,
    isDeliveryServiceLoading,
    uploaderRef,
    setDirty,
    setIsSaving,
    setImageData,
    setFileInfo,
    handleFormInputChange,
    updateSupplierProfile,
    updateAccountSettings,
  }), [
    supplierData,
    deliveryData,
    supplierInfo,
    isSaving,
    fetchedSupId,
    fileInfo,
    isUploadingImage,
    isDirty,
    isSupplierInfoLoading,
    isDeliveryServiceLoading,
  ])
}

export default useSupplierProfileData
