import { useEffect, useRef } from 'react'
import { useRouter } from 'next/router'

import { debounce } from '../../utils/event-utils'
import searchEvent from '../../utils/analytics/searchEvent'
import { SearchResults } from '../../utils/types/search'
import { getCountryAndLocaleStrings } from '../../utils/locales'

interface UseSearchEventParams {
  searchTerm: string
  searchResults: SearchResults
  resultQuantity: number
  isLoading: boolean
}

// Pushes analytics search event to datalayer
const useSearchEvent = (useSearchEventParams: UseSearchEventParams) => {
  const {
    searchTerm,
    searchResults,
    resultQuantity,
    isLoading,
  } = useSearchEventParams
  const { locale: countryAndLocale } = useRouter()
  const { locale, country } = getCountryAndLocaleStrings(countryAndLocale)

  const { current: triggerEvent } = useRef(debounce((args) => searchEvent(args), 3000))

  useEffect(() => {
    if (!isLoading && searchTerm && searchTerm !== '*') {
      triggerEvent({ searchTerm, locale, country, searchResults, resultQuantity })
    }
  }, [searchTerm, triggerEvent, isLoading, locale, country])
}

export default useSearchEvent
