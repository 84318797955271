import { FC, Fragment, useState } from 'react'
import { Button, List } from 'rsuite'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'

import MobileMenuItem from '../MobileUI/MobileMenuItem'
import useCategoryDataStructure from '../../../hooks/useCategoryDataStructure'
import { Catalog } from '../../../../utils/types/Product'
import { useApi } from '../../../../services/useApi'
import { QueryParam, ResourceTag } from '../../../../utils/constants'
import { getProductsCount } from '../../../../lib/product'
import RenderCatalogNode from './RenderCatalogNode'

import styles from '../../../../styles/Header.module.less'

interface MobileCategoryPickerProps {
  handleSelectAndCloseMenus: (categoryCode?: string) => void
  selectedSubCategory: Catalog
}

const MobileCategoryPicker: FC<MobileCategoryPickerProps> = (props) => {
  const { handleSelectAndCloseMenus, selectedSubCategory } = props

  const [selectedSubCategoryItem, setSelectedSubCategoryItem] = useState('')

  const { t } = useTranslation('products')
  const { query } = useRouter()
  const { getResourceCount } = useApi()

  const searchQuery = query[QueryParam.search]?.toString()
  const countQuery = searchQuery ? 'aggregate=index.catalog.id' : 'aggregate=index.catalog.id'
  const { data: productsAggregate } = getResourceCount(ResourceTag.product, countQuery)

  const { selectedCategory, parentNodes } = useCategoryDataStructure()

  const isSelectedSubCatMatch = (categoryCode: string) => categoryCode === selectedSubCategoryItem
  const isSelectedCategory = (categoryCode: string) => categoryCode === selectedCategory
  const isAnySubCatActive = (
    categoryCode: string,
  ) => parentNodes.slice(1).find((node) => node.code === categoryCode)

  const handleSubCatMenuItemClick = (
    subCatCode: string,
  ) => setSelectedSubCategoryItem((prevCode) => {
    if (prevCode === subCatCode) return ''
    return subCatCode
  })

  const setMobileMenuItemActiveStyles = (categoryCode: string) => {
    const classNames = []
    const isOpened = selectedSubCategoryItem === categoryCode

    if (!isOpened && isAnySubCatActive(categoryCode)) classNames.push(styles['active-category-label'])
    if (isOpened) classNames.push(styles.active)

    return classNames.join(' ')
  }

  const renderCatalogItem = (subCategoryData: Catalog) => {
    const productsCount = getProductsCount(productsAggregate, subCategoryData)

    return (
      <List.Item
        role="button"
        key={subCategoryData['catalog.code']}
        className={`
          margin-bottom-spacer
          box-shadow-none
          ${isAnySubCatActive(subCategoryData['catalog.code']) ? styles['active-category-label'] : ''}
          ${styles['mobile-menu-item']}
        `}
        onClick={() => (productsCount ? handleSelectAndCloseMenus(subCategoryData['catalog.code']) : undefined)}
      >
        <RenderCatalogNode productsCount={productsCount} catalogLabel={subCategoryData['catalog.label']} />
      </List.Item>
    )
  }

  return (
    <div className={styles['mobile-category-picker-container']}>
      <Button
        appearance="subtle"
        className={`
          margin-bottom-spacer-tripple
          padding-zero
          ${isSelectedCategory(selectedSubCategory['catalog.code']) ? 'text-color-primary' : ''}
          ${styles['mobile-menu-subheader']}
        `}
        onClick={() => handleSelectAndCloseMenus(selectedSubCategory['catalog.code'])}
      >
        {t('Browse all {{category}}', { category: selectedSubCategory['catalog.label'] })}
      </Button>
      {selectedSubCategory.catalog?.map((categoryData) => {
        const isSubCatSelected = isSelectedCategory(categoryData['catalog.code'])
        const productsCount = getProductsCount(productsAggregate, categoryData)

        return (
          <Fragment key={categoryData['catalog.code']}>
            <MobileMenuItem
              isExpandable
              isActive={isSubCatSelected}
              handleClick={productsCount ? () => handleSubCatMenuItemClick(categoryData['catalog.code']) : undefined}
              additionalClassNames={setMobileMenuItemActiveStyles(categoryData['catalog.code'])}
            >
              <RenderCatalogNode productsCount={productsCount} catalogLabel={categoryData['catalog.label']} />
            </MobileMenuItem>
            {isSelectedSubCatMatch(categoryData['catalog.code']) && (
            <List
              className="padding-left-spacer-double margin-bottom-spacer-double box-shadow-none"
              bordered={false}
              size="sm"
            >
              <List.Item
                role="button"
                className={`
                    margin-bottom-spacer
                    box-shadow-none
                    ${isSubCatSelected ? 'text-color-primary' : ''}
                  `}
                onClick={() => handleSelectAndCloseMenus(categoryData['catalog.code'])}
              >
                <b>{t('All {{category}}', { category: categoryData['catalog.label'] })}</b>
                <span className={styles['product-count']}>
                  {productsCount?.attributes || 0}
                </span>
              </List.Item>
              {categoryData.catalog?.map((subCategoryData) => renderCatalogItem(subCategoryData))}
            </List>
            )}
          </Fragment>
        )
      })}
    </div>
  )
}

export default MobileCategoryPicker
