import React from 'react'
import Link from 'next/link'
import { Nav } from 'rsuite'
import useTranslation from 'next-translate/useTranslation'
import { IconProps } from '@rsuite/icons/lib/Icon'

import { useAuth } from '../../../services/useAuth'
import AnchorLink from '../../AnchorLink'
import { HeaderLinks } from '../../../utils/types/misc'
import contactEvent from '../../../utils/analytics/contactEvent'
import { ContactContext } from '../../../utils/types/analytics'

interface NavigationLinkProps extends HeaderLinks {
  icon?: React.ReactElement<IconProps, string>
  contactEvent?: ContactContext
}

const NavigationLink: React.FC<NavigationLinkProps> = (props) => {
  const { user } = useAuth()
  const { t } = useTranslation('common')
  const {
    href,
    translateURL,
    label,
    icon,
    loggedIn,
    className,
    loggedOut,
    rightDropDown,
    topDivider,
    contactContext,
  } = props
  if ((loggedIn && user === null) || (loggedOut && user !== null) || !href) {
    return null
  }
  if (rightDropDown && icon) {
    return (
      <>
        {topDivider && <Nav.Item divider />}
        <Nav.Item
          as={AnchorLink}
          className={className}
          icon={icon}
          href={translateURL ? t(href) : href}
        >
          {t(label)}
        </Nav.Item>
      </>
    )
  }
  return (
    <Link
      href={translateURL ? t(href) : href}
      passHref
    >
      <a
        // Href needs to be duplicate as Link due to onClick linter warning
        href={translateURL ? t(href) : href}
        className={className}
        role="button"
        // ANALYTICS-EVENT: Contact event
        onClick={() => contactContext && contactEvent(contactContext)}
      >
        {t(label)}
      </a>
    </Link>
  )
}
export default NavigationLink
