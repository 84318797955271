import { FC, RefObject } from 'react'
import { Container, Popover, Whisper } from 'rsuite'
import useTranslation from 'next-translate/useTranslation'

import { HOVER_DELAY } from '../../../../utils/constants'
import { useAuth } from '../../../../services/useAuth'
import ProfileIconPickerContent from '../ProfileIconPicker/ProfileIconPickerContent'
import SignInPickerContent from './SignInPickerContent'

import styles from '../../../../styles/SignInPicker.module.less'
import ProfileIconPickerStyles from '../../../../styles/ProfileIconPicker.module.less'

interface SignInPickerProps {
  handleProfileIconClick: (url: string, icon: 'profile' | 'heart') => void
  topHeaderRef?: RefObject<HTMLDivElement>
}

const SignInPicker: FC<SignInPickerProps> = (props) => {
  const { handleProfileIconClick, topHeaderRef } = props
  const { t } = useTranslation('auth')

  const { user } = useAuth()

  const loggedInGreeting = user?.firstname ? t('Hello name', { name: user.firstname }) : `${t('Hi there')},`

  return (
    <Whisper
      trigger="hover"
      enterable
      placement="bottomEnd"
      container={topHeaderRef ? topHeaderRef.current || undefined : undefined}
      delayOpen={HOVER_DELAY}
      speaker={(
        <Popover className={user ? ProfileIconPickerStyles['picker-popover'] : styles['signin-popover']}>
          {user ? <ProfileIconPickerContent /> : <SignInPickerContent />}
        </Popover>
        )}
    >
      <Container
        className="cursor-pointer flex-shrink-col-static"
        onClick={() => handleProfileIconClick('/my-account', 'profile')}
      >
        <b>{user ? loggedInGreeting : t('Sign in to your')}</b>
        <b className={styles['highlight-text']}>{t('Account & Catalogs')}</b>
      </Container>
    </Whisper>
  )
}

export default SignInPicker
