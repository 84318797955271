import { PRIMARY_DARKER } from '../../utils/constants'

const CheckIcon = ({ color = PRIMARY_DARKER }) => (
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="7.5" cy="7.5" r="7.5" fill={color} />
    <path d="M10.9518 4.77564C11.0584 4.66271 11.2364 4.65762 11.3494 4.76428C11.4497 4.85908 11.4649 5.01026 11.3921 5.122L11.3607 5.16186L6.57947 10.2244C6.47919 10.3306 6.31731 10.3405 6.20537 10.2557L6.16595 10.2194L3.6347 7.4069C3.53079 7.29144 3.54015 7.11361 3.65561 7.0097C3.75823 6.91733 3.91015 6.91447 4.01562 6.99606L4.0528 7.0306L6.3795 9.61613L10.9518 4.77564Z" fill="white" stroke="white" strokeWidth="0.5" />
  </svg>
)

export const UnFilledCheckIcon = () => (
  <svg width="15" height="15" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.5977 6.05371C11.6934 5.95236 11.8532 5.9478 11.9545 6.04351C12.0446 6.12859 12.0582 6.26427 11.9929 6.36454L11.9647 6.40032L7.67385 10.9436C7.58385 11.0389 7.43857 11.0479 7.33811 10.9717L7.30274 10.9391L5.0311 8.41509C4.93785 8.31148 4.94625 8.15189 5.04987 8.05863C5.14197 7.97574 5.2783 7.97317 5.37295 8.04639L5.40632 8.0774L7.49439 10.3977L11.5977 6.05371Z" fill="#575757" stroke="#8E8E93" strokeWidth="0.6" />
    <circle cx="8.5" cy="8.5" r="7.5" stroke="#8E8E93" />
  </svg>
)

export default CheckIcon
