import React, { FC } from 'react'

import useFooterData from '../../../services/strapi/useFooterData'
import AnchorLink from '../../AnchorLink'
import CountryPicker from '../Header/CountryPicker/CountryPicker'
import useModalLinkHandler from '../../Modals/hooks/useModalLinkHandler'
import { StrapiFooterResp } from '../../../utils/types/strapi'

import styles from '../../../styles/FooterBottomRow.module.less'

interface FooterBottomRowProps {
  // Workaround to include footer content via SSG
  // TODO: Implement SSG footer globally to Layout component in Next.js 13+
  footerContentResp?: StrapiFooterResp

}

const FooterBottomRow: FC<FooterBottomRowProps> = ({ footerContentResp }) => {
  const { footerBottomData } = useFooterData()

  const footerData = footerContentResp?.data?.[0]?.attributes?.footerBottom || footerBottomData

  const handleModalLinkClick = useModalLinkHandler({ analyticsLocation: 'main_footer' })

  return (
    <nav className={styles['footer-bottom-row']}>
      <div className={styles['footer-bottom-row-inner']}>
        <div className={`${styles['footer-copyright']} h6`}>
          &copy;
          {' '}
          {new Date().getFullYear()}
          ,
          {' '}
          {footerData?.businessEntity}
        </div>
        <ul>
          <li className={styles['country-dropdown']}>
            <CountryPicker
              showCountryLabelMobile
              isFlagVisible={false}
              placement="topEnd"
              className={styles['footer-link']}
            />
          </li>
          {footerData?.footerBottomLinks.map((item) => (
            <li key={(item.link ?? '') + item.label}>
              <AnchorLink
                onClick={(e) => handleModalLinkClick(e, item.link)}
                href={item.link}
                target={item.target || undefined}
                className={styles['footer-link']}
              >
                {item.label}
              </AnchorLink>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  )
}

export default FooterBottomRow
