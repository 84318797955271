import { Translate } from 'next-translate'

import { PartialProduct, Product, ProductText, Supplier, CompactTexts } from '../utils/types/Product'
import { Locale } from '../external'
import { capitalizeFirstLetter } from '../utils/util'
import { getSupplierName } from './supplier'
import { getProductSalesCount, getSalesUnit } from './salesUnit'

export interface WithTexts extends CompactTexts<ProductText['text.type']> {
  id?: string
  text?: ProductText[]
}

export interface SimplifiedProductNameParams {
  product?: Product
  supplier?: Supplier
}

export const getTextObjectByType = <Resource extends WithTexts>(p: Resource, type: string) => (
  p.text?.find((text) => text['text.type'] === type))

export const getTextContentByType = <Resource extends WithTexts>(resource: Resource, type: ProductText['text.type']) => {
  // First check for compact text, then from full text array
  const content = resource[`text.${type}`]
  if (content) {
    return content
  }

  const productText = getTextObjectByType(resource, type)
  if (productText) {
    return productText['text.content']
  }

  return ''
}

export const getBrandName = (product: Product | PartialProduct): string => (
  getTextContentByType(product, 'brand')
)

export const getModelName = (product: Product | PartialProduct): string => (
  getTextContentByType(product, 'model')
)

export const getProductName = (
  product?: Product | PartialProduct,
) => (
  product ? getTextContentByType(product, 'name') : ''
)

export const checkIsDefaultLabel = (
  label: ProductText['text.label'] | undefined,
) => !label?.includes('Auto translated')

export const getProductNameObject = (
  product: Product | PartialProduct,
  locale?: Locale,
  base = false,
) => (
  product?.text?.find((ele) => (
    ele['text.type'] === 'name'
    && (!locale || locale === ele['text.languageid'])
    && (!base || checkIsDefaultLabel(ele['text.label']))
  )))

export const getProductNameBase = (
  product?: Product | PartialProduct,
) => (
  product
    ? getProductNameObject(product, undefined, true)?.['text.content'] ?? product?.['product.label']
    : undefined
)

export const eliminateSpecialChars = (
  text: string,
) => text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string

export const getSimplifiedProductName = ({
  product,
  supplier,
}: SimplifiedProductNameParams) => {
  if (!product) {
    return ''
  }

  const supplierName = getSupplierName(supplier)
  const brandName = getBrandName(product)
  const productNameText = getProductName(product)
  let productName = productNameText || product?.['product.label'] || ''

  const removeNameFromProduct = (name: string, prodName: string) => {
    const newText = eliminateSpecialChars(name)
    const regExp = new RegExp(`\\b${newText}\\b`, 'i')
    const updatedProdName = prodName.replace(regExp, '').trim()

    return capitalizeFirstLetter(updatedProdName)
  }

  productName = removeNameFromProduct(brandName, productName)
  productName = removeNameFromProduct(supplierName, productName)

  return productName
}

// Remove supplier prefix from SKU
export const getProductBaseSKU = (product?: Product | PartialProduct) => (
  product?.['product.code'].split('-').slice(1).join('-') || ''
)

export const getOriginalText = (t: ProductText[], title: ProductText['text.type']) => t
  .filter((text) => text['text.type'] === title)
  .find((text) => !text['text.label'].includes('Auto translated'))

// Index is used for image order index in single product gallery
export const getProductImageAltText = (product: Product, index?: number) => {
  const productBrand = getBrandName(product)
  const productSku = getProductBaseSKU(product)
  const productName = getSimplifiedProductName({ product })
  return `${productBrand} ${productSku}, ${productName}${index ? `, image ${index}` : ''}`
}

export const getProductHeadingText = (product: Product, supplier: Supplier, t: Translate) => {
  const productBaseSKU = getProductBaseSKU(product)
  const productName = getSimplifiedProductName({ product, supplier })
  const productSalesCount = getProductSalesCount(product, t)
  const supplierName = getSupplierName(supplier)
  const brandName = getBrandName(product)

  return `${brandName || supplierName} ${productBaseSKU} ${productName}${productSalesCount ? `, ${productSalesCount}` : ''}`
}

export const removeBrandModelTextRegex = (
  oldBrandName: string | undefined,
  oldModelName: string | undefined,
) => {
  const escapedBrandName = eliminateSpecialChars(oldBrandName || '')
  const escapedModelName = eliminateSpecialChars(oldModelName || '')
  return new RegExp(`^${escapedBrandName}[\\s]?${escapedModelName}[\\s]?`)
}
