import { createContext, useContext, useState, useEffect, useCallback, useMemo } from 'react'
import { FormInstance } from 'rsuite/Form'
import isEmpty from 'lodash/isEmpty'
import dynamic from 'next/dynamic'
import useTranslation from 'next-translate/useTranslation'

import { CartResponse, OrderResponse, SingleOrderResponse, WithSuppliers } from '../../utils/types/Order'
import useFormError, { FormError } from '../../services/useFormError'
import fetcher from '../../utils/fetcher'
import { safeAwait } from '../../lib/responseHandlers'
import { useAdminApi } from '../../services/useApi'
import { Customer } from '../../utils/types/Product'
import useAdminCustomers, { AdminCustomerFunctions } from '../../services/useAdminCustomers'
import { useCart } from '../Cart/CartProvider'
import useInitOrder from '../BuyerProfile/OrderHistory/useInitOrder'
import { showErrorNotification } from '../../utils/notify'
import { authSubject, useAuth } from '../../services/useAuth'
import SafeTranslate from '../common/SafeTranslate'
import { selectNormalizedCustomer } from '../BuyerProfile/Account/selectors'
import { useCustomer } from '../BuyerProfile/Account/CustomerProvider'
import { CustomerResponse } from '../../utils/types/customer'
import withCustomer from '../../services/withCustomer'
import useCopyToCart from './useCopyToCart'

import styles from '../../styles/ImpersonationToolbar.module.less'

const ConfirmModal = dynamic(() => import('../Modals/ConfirmModal'))
const EndSessionModal = dynamic(() => import('../Modals/EndSessionModal'))

export type ImpersonationFormValues = {
  shouldLoadOrder?: boolean
  orderId: string
  customerId?: undefined
} | {
  shouldLoadOrder?: boolean
  orderId?: undefined
  customerId: string
} | {
  shouldLoadOrder: boolean
  orderId?: undefined
  customerId?: undefined
} | {
  shouldLoadOrder?: boolean
  orderId?: undefined
  customerId?: undefined
}

export type EndSession = {
  end: boolean
  startNew?: boolean
}

type ImpersonationContextProps = {
  isProcessing: boolean
  isLoading: boolean
  formValues: ImpersonationFormValues
  formRef: React.MutableRefObject<FormInstance<ImpersonationFormValues> | null>
  customerList: AdminCustomerFunctions['customerList']
  selectedCustomer: AdminCustomerFunctions['selectedCustomer']
  handleCustomerSearch: AdminCustomerFunctions['setSearchWord']
  handleCustomerSelect: AdminCustomerFunctions['setSelectedId']
  customer: Customer | undefined
  customerName: string
  confirmEndSession: EndSession
  confirmedValue: ImpersonationFormValues | undefined
  handleEndSession: (end: boolean, startNew?: boolean) => void
  handleConfirmSelectedValue: (impersonationValues: ImpersonationFormValues) => void
  handleFormChange: (values: ImpersonationFormValues) => void
  handleLoadOrder: (orderId: string) => Promise<string>
  handleSetIsProcessing: (isProcessing: boolean) => void
  handleSetIsLoading: (isLoading: boolean) => void
  handleFormError: (errors: FormError<ImpersonationFormValues>) => void
  handleSetCustomerIdFromOrder: (customerId: string | undefined) => void
  handleSetOldCart: (cart: WithSuppliers<CartResponse> | null | undefined) => void
}

const ImpersonationContext = createContext<ImpersonationContextProps>({
  isProcessing: false,
  isLoading: false,
  formValues: {},
  formRef: { current: null },
  customerList: [],
  selectedCustomer: undefined,
  customer: undefined,
  customerName: '',
  confirmedValue: undefined,
  confirmEndSession: { end: false },
  handleCustomerSearch: () => {},
  handleCustomerSelect: () => {},
  handleConfirmSelectedValue: () => {},
  handleEndSession: () => {},
  handleFormChange: () => {},
  handleLoadOrder: () => Promise.resolve(''),
  handleSetIsProcessing: () => undefined,
  handleSetIsLoading: () => undefined,
  handleFormError: () => undefined,
  handleSetCustomerIdFromOrder: () => {},
  handleSetOldCart: () => {},
})

const apiUrl = process.env.NEXT_PUBLIC_API_URL

const ImpersonationProvider: React.FC = ({ children }) => {
  const [isProcessing, setIsProcessing] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [formValues, setFormValues] = useState<ImpersonationFormValues>({})
  const [customerIdFromOrder, setCustomerIdFromOrder] = useState<string | undefined>()
  const [oldCart, setOldCart] = useState<ReturnType<typeof useCart>['cartState']['cart']>()
  const [confirmedValue, setConfirmedValue] = useState<ImpersonationFormValues>()
  const [confirmEndSession, setConfirmEndSession] = useState<EndSession>({
    end: false,
  })

  const {
    onAddOrderToCart,
    onAddOldCartToCart,
  } = useCopyToCart()

  const { t } = useTranslation()

  const {
    cartState: { cart: mainCart },
    clearCart,
    refresh,
  } = useCart()

  const { user, isImpersonated, impersonate, impersonateLeave } = useAuth()

  const { customerResponse } = useCustomer()

  const {
    orderData: orderResponseData,
  } = useInitOrder<WithSuppliers<OrderResponse>>(
    confirmedValue?.shouldLoadOrder ? formValues.orderId : undefined, true,
  )

  const order = useMemo(() => {
    if (!orderResponseData) {
      return undefined
    }

    return {
      ...orderResponseData,
      data: orderResponseData.data?.[0] || {},
    } as WithSuppliers<SingleOrderResponse<'order'>>
  }, [orderResponseData])

  const { getSingleResource } = useAdminApi()
  const { formRef, handleFormError } = useFormError<ImpersonationFormValues>()
  const {
    customerList,
    setSearchWord,
    setSelectedId,
    selectedCustomer,
  } = useAdminCustomers(false)

  const {
    data: customerData,
    isLoading: isLoadingCustomer,
  } = getSingleResource<Customer>(customerIdFromOrder ? 'customer' : null, `&id=${customerIdFromOrder}`)

  useEffect(() => {
    setSelectedId(customerData)
  }, [customerData])

  const handleFormChange = useCallback((values: ImpersonationFormValues) => {
    setFormValues(values)
  }, [setFormValues])

  const handleLoadOrder = useCallback(async (orderId: string) => {
    const baseUrl = `${apiUrl}/admin/default/jsonadm`
    const orderUrl = `${baseUrl}/order?id=${orderId}`

    setIsLoading(true)
    const [err, data] = await safeAwait<OrderResponse>(fetcher(orderUrl))
    setIsLoading(false)

    if (err) {
      throw new Error('Something went wrong')
    }

    if (isEmpty(data?.data)) {
      throw new Error('Order not found')
    }

    const customerId = data?.data?.[0]?.attributes?.['order.customerid']

    if (!customerId) {
      throw new Error('Order is not linked to any buyer account')
    }

    setCustomerIdFromOrder(customerId)

    return customerId
  }, [setCustomerIdFromOrder, setIsLoading])

  const handleSetIsProcessing = useCallback((processing: boolean) => {
    setIsProcessing(processing)
  }, [])

  const handleSetIsLoading = useCallback((loading: boolean) => {
    setIsLoading(loading)
  }, [])

  const handleSetCustomerIdFromOrder = useCallback((customerId: string | undefined) => {
    setCustomerIdFromOrder(customerId)
  }, [setCustomerIdFromOrder])

  const handleValueConfirm = async () => {
    setConfirmedValue(undefined)

    if (confirmedValue?.customerId) {
      handleSetIsProcessing(true)
      setOldCart(mainCart)
      await impersonate(confirmedValue.customerId)
      await refresh()
      await clearCart()

      if (oldCart) {
        await onAddOldCartToCart(oldCart)
      }

      handleSetIsProcessing(false)

      return
    }

    if (confirmedValue?.orderId && selectedCustomer) {
      handleSetIsProcessing(true)
      setConfirmedValue({ shouldLoadOrder: true })
      await impersonate(selectedCustomer.id)
      handleSetIsProcessing(false)
    }
  }

  const startNewSession = async (orderId: string) => {
    try {
      await handleLoadOrder(orderId)
      setConfirmedValue({ orderId })
    } catch (error) {
      showErrorNotification('Error', (error as Error).message)
    }
  }

  const handleConfirmEndSession = async () => {
    if (isImpersonated && !confirmEndSession.startNew) {
      handleFormChange({})
    }

    setSelectedId(undefined)
    setOldCart(null)
    handleSetCustomerIdFromOrder(undefined)
    clearCart()
    await impersonateLeave()
  }

  const handleCloseEndSession = () => {
    setConfirmEndSession({ end: false })

    if (confirmEndSession.startNew && formValues.orderId && !isImpersonated) {
      startNewSession(formValues.orderId)
    }
  }

  const handleAbort = () => {
    setConfirmedValue(undefined)
  }

  const handleEndSession = useCallback(async (end: boolean, startNew?: boolean) => {
    setConfirmEndSession((curr) => ({ ...curr, end, startNew }))
  }, [setConfirmEndSession])

  const handleConfirmSelectedValue = useCallback((impersonationValues: ImpersonationFormValues) => {
    setConfirmedValue(impersonationValues)
  }, [setConfirmedValue])

  const handleSetOldCart = useCallback((cart: WithSuppliers<CartResponse> | null | undefined) => {
    setOldCart(cart)
  }, [setOldCart])

  useEffect(() => {
    authSubject.subscribe((event) => {
      switch (event) {
        case 'LOGGED_OUT':
          setSelectedId(undefined)
          setOldCart(null)
          setCustomerIdFromOrder(undefined)
          clearCart()
          break
        default:
          break
      }
    })

    return () => {
      authSubject.unsubscribe()
    }
  }, [])

  // Order override
  useEffect(() => {
    if (
      order?.data
      && order.included
      && order.included.length > 0
      && !isProcessing
    ) {
      (async () => {
        setConfirmedValue(undefined)
        handleSetIsProcessing(true)
        await onAddOrderToCart(order)
        handleSetIsProcessing(false)
      })()
    }
  }, [
    order?.data,
    isProcessing,
    formValues.orderId,
  ])

  const customer = useMemo(() => (
    isImpersonated && user ? {
      'customer.company': user.company || user.firstname || 'Guest user',
      'customer.code': user.email,
    } as Customer : undefined
  ), [isImpersonated, user])

  const getName = (customers: CustomerResponse) => {
    const {
      fullName,
      email,
    } = selectNormalizedCustomer(customers)

    return fullName || email || ''
  }

  const customerName = useMemo(() => (
    customerResponse ? getName(customerResponse) : ''
  ), [customerResponse])

  return (
    <ImpersonationContext.Provider value={{
      isProcessing,
      isLoading: isLoading || isLoadingCustomer,
      formRef,
      formValues,
      selectedCustomer,
      customerList,
      customer,
      customerName,
      confirmEndSession,
      confirmedValue,
      handleSetIsProcessing,
      handleSetIsLoading,
      handleFormChange,
      handleFormError,
      handleSetCustomerIdFromOrder,
      handleLoadOrder,
      handleEndSession,
      handleConfirmSelectedValue,
      handleCustomerSearch: setSearchWord,
      handleCustomerSelect: setSelectedId,
      handleSetOldCart,
    }}
    >
      {children}
      <ConfirmModal
        modalClassName={`${styles['force-vertical-scrollbar']} ${styles['confirm-modal']}`}
        confirm={handleValueConfirm}
        cancel={handleAbort}
        open={!!confirmedValue?.orderId || !!confirmedValue?.customerId}
        closeButtonText={t('checkout:Back to marketplace')}
        confirmButtonText={t('cart:Confirm')}
        title={t('checkout:Confirm account selection')}
        content={(
          <SafeTranslate
            i18nKey="auth:You are selecting <b>{{name}} / {{email}}</b> as the account to act as during the session Please confirm the selection"
            values={{
              name: selectedCustomer?.['customer.company'] || '',
              email: selectedCustomer?.['customer.code'] || '',
            }}
            components={{
              b: <b />,
            }}
          />
        )}
        backdrop="static"
        shouldDisplayCloseIcon={false}
      />
      <EndSessionModal
        modalClassName={styles['force-vertical-scrollbar']}
        name={customerName}
        open={confirmEndSession.end}
        cancel={handleCloseEndSession}
        onConfirm={handleConfirmEndSession}
      />
    </ImpersonationContext.Provider>
  )
}

export default withCustomer(ImpersonationProvider)

export const useImpersonation = () => useContext(ImpersonationContext)
