import { FC } from 'react'
import { Drawer } from 'rsuite'
import useTranslation from 'next-translate/useTranslation'
import SearchIcon from '@rsuite/icons/Search'

import SearchBar from './Search'
import MobileDrawerHeader from '../MobileUI/MobileDrawerHeader'
import { capitalizeFirstLetter } from '../../../../utils/util'
import useDrawerState from '../hooks/useDrawerState'

import styles from '../../../../styles/MobileSearch.module.less'

const MobileSearch: FC = () => {
  const {
    isDrawerOpen,
    toggleDrawerOpenState,
  } = useDrawerState()

  const { t } = useTranslation('url')

  return (
    <>
      <SearchIcon className="icon-size-double" onClick={toggleDrawerOpenState} />
      <Drawer
        size="xs"
        placement="top"
        open={isDrawerOpen}
        onClose={toggleDrawerOpenState}
        className={styles['mobile-search']}
      >
        <MobileDrawerHeader>
          {capitalizeFirstLetter(t('search'))}
        </MobileDrawerHeader>
        <Drawer.Body className="padding-spacer-double">
          <SearchBar externalSearchCallback={toggleDrawerOpenState} />
        </Drawer.Body>
      </Drawer>
    </>
  )
}

export default MobileSearch
