import { ReactNode, useState, Fragment, FC } from 'react'
import { ErrorBoundary, FallbackProps, ErrorBoundaryPropsWithComponent } from 'react-error-boundary'
import { useRouter } from 'next/router'

import Layout from '../Layouts/PageLayout'
import ErrorFallback from './ErrorFallback'

interface ErrorBoundaryWrapperProps {
  children: ReactNode
  includeLayout?: boolean
}

type OnError = ErrorBoundaryPropsWithComponent['onError']

const ErrorBoundaryWrapper: FC<ErrorBoundaryWrapperProps> = ({ children, includeLayout }) => {
  const [errorStackMessage, setErrorStackMessage] = useState('')
  const { asPath } = useRouter()

  const ChildrenWrapper = includeLayout ? Layout : Fragment

  const FallbackComponent = (props: FallbackProps) => (
    <ChildrenWrapper>
      <ErrorFallback {...props} errorStackMessage={errorStackMessage.trim()} />
    </ChildrenWrapper>
  )

  const handleError: OnError = (_, { componentStack }) => setErrorStackMessage(componentStack)

  return (
    <ErrorBoundary
      FallbackComponent={FallbackComponent}
      onError={handleError}
      resetKeys={[asPath]}
    >
      {children}
    </ErrorBoundary>
  )
}

export default ErrorBoundaryWrapper
