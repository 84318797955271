import { getProductPrice } from '../lib/resources/price'
import { checkIsDefaultLabel } from '../lib/text'
import { DEFAULT_PRODUCT_POSITION, RFQ_PRODUCT_POSITION } from './constants'
import { PartialProduct, ProductStock, Customer, Product } from './types/Product'
import { ResourceName } from './types/Resource'

type ProductResources = 'price' | 'text' | 'product/property' | 'catalog' | 'attribute' | 'product' | 'supplier' | 'media' | 'rule'

export const getSaveProductMutationQuery = (
  data: PartialProduct,
  updatedFields?: PartialProduct,
) => {
  let productProperty = ''
  let productPrice = ''
  let productCatalog = ''
  let productAttribute = ''
  let productText = ''
  let productVariants = ''
  let productSupplier = ''
  let productMedia = ''

  data.media?.forEach((media) => {
    productMedia += media.id ? `{
      refid: "${media.id}"
      position: ${media['product.lists.position']}
    }
    ` : ''
  })

  data.supplier?.forEach((supplier) => {
    productSupplier += `{
      type: "default"
      domain: "supplier"
      refid: "${supplier.id}"
    }
    `
  })

  const isUpdate = !!updatedFields

  data['product/property']?.forEach((property) => {
    productProperty += `{
        ${isUpdate ? `id: "${property?.id}"` : ''}
        languageid:null
        type:"${property['product.property.type']}"
        value:"${property['product.property.value']}"
        }
        `
  })
  data.catalog.forEach((catalog) => {
    productCatalog += `{
        domain: "product"
        refid:"${catalog.id}"
        type:"default"
        position: ${catalog['catalog.lists.position'] ?? (getProductPrice(data as Product) ? DEFAULT_PRODUCT_POSITION : RFQ_PRODUCT_POSITION)}
        }
        `
  })
  data.attribute.forEach((attribute) => {
    const newAttribute = `{
      domain: "product"
      refid:"${attribute.id}"
      type:"${attribute['product.lists.type'] ?? 'default'}"
      }
      `

    if (isUpdate) {
      const shouldUpdateAttribute = updatedFields.attribute.some((attr) => attr.id === attribute.id)

      // Send same query which we send on saving attribute i.e domain, refid and type
      productAttribute += shouldUpdateAttribute
        ? newAttribute
      // Send only id when that specific attribute is not updated
        : `{
        ${attribute['product.lists.id'] ? `id:"${attribute['product.lists.id']}"` : ''}
        }
        `
    } else { // Product save
      productAttribute += newAttribute
    }
  })

  data.price.forEach((price) => {
    const skuTaxPrice = typeof price['price.taxrates'] === 'string'
      ? JSON.parse(price['price.taxrates'])?.tax || '0'
      : '0'

    productPrice += `{
        domain: "product"
        ${isUpdate ? `refid: "${price?.id}"` : ''}
        item:{
        costs:"${price['price.costs'] ?? ''}"
        value:"${price['price.value'] ?? ''}"
        taxrate: ${skuTaxPrice}
        quantity:${price['price.quantity'] ?? 0}
        currencyid: "${price['price.currencyid']}"
        label:"${price['price.label']}"
        type:"${price['price.type'] ?? 'default'}"
        }
      }
        `
  })
  data.text.forEach((text) => {
    productText += `{
          domain: "product"
          ${isUpdate ? `refid: "${text?.id}"` : ''}
          ${checkIsDefaultLabel(text['text.label']) ? `item: {
            id: "${text?.id}"
            label: """${text['text.label']}"""
            type: "${text['text.type']}"
            languageid: ${text['text.languageid'] ? `"${text['text.languageid']}"` : 'null'}
            content: """${text['text.content']}"""
          }` : ''}
      }
        `
  })

  data.product.forEach((variant, idx) => {
    const mainProductMoq = data['product/property']?.find((prop) => prop['product.property.type'] === 'manual-stock-limit')

    const variantProperty = variant['product/property'].length
      ? `property: [
      ${variant['product/property'].map(
    (property) => `
      {
        ${property.id ? `id: "${property.id}"` : ''}
        languageid:null
        type:"${property['product.property.type']}"
        value:"${property['product.property.value']}"
      }`,
  )}
     ]
    `
      // Providing main product MOQ for variant products when same MOQ is selected
      : `property: [{
        ${mainProductMoq?.id ? `id: "${mainProductMoq?.id}"` : ''}
        languageid:null
        type:"${mainProductMoq?.['product.property.type']}"
        value:"${mainProductMoq?.['product.property.value']}"
        }]
        `

    productVariants += `{
        position: ${idx}
        item:{
         ${variant?.id ? `id: "${variant?.id}"` : ''}
          code: "${variant['product.code']}"
          type: "${variant['product.type']}"
          status: 1
          label:"${variant['product.label']}"
          ${variantProperty}
          lists:{
            attribute: [{
              type: "variant"
              domain: "attribute"
              ${variant.attribute[0]?.id ? `refid: "${variant.attribute[0]?.id}"` : ''} # // id of the color attribute
              ${variant.attribute[0]?.['product.lists.id'] ? `id: "${variant.attribute[0]?.['product.lists.id']}"` : ''}
              ${!variant.attribute[0]?.id ? `
              item: {
                code: "${variant.attribute[0]?.['attribute.code']}"
                label: "${variant.attribute[0]?.['attribute.label']}"
                type: "${variant.attribute[0]?.['attribute.type']}"
                domain: "${variant.attribute[0]?.['attribute.domain']}"
              }` : ''}
          }]
          }


        }
      }
        `
  })

  // This function will check whether to add the resource in the query on the basis of
  // two conditions
  // 1. For saving new product, it will always add the resource
  // 2 . For update product, it will only add if that resource has been updated. E.g if any
  // text field is updated then it will add 'text' resource
  const shouldSaveResource = (
    resource: Extract<Exclude<ResourceName, 'rule'>, ProductResources>,
    resourceData: string,
    fieldName?: string,
  ) => {
    if (!isUpdate || !!updatedFields?.[resource]?.length) {
      return `${fieldName ?? resource}: [${resourceData}]`
    }
    return ''
  }

  const variantsUpdateLogic = () => {
    // Case when product is un-archiving
    if (!data.text.length && !data.attribute.length) {
      return ''
    }
    return productVariants.length > 1 ? shouldSaveResource('product', productVariants) : 'product: []'
  }

  return `mutation {
        saveProduct(input: {
          code: "${data['product.code']}"
          status:${data['product.status']}
          type:"${data['product.type']}"
          label:"${data['product.label']}"
          ${isUpdate ? `id: "${updatedFields?.id}"` : ''}
          ${shouldSaveResource('product/property', productProperty, 'property')}

          lists:{
              ${variantsUpdateLogic()}
              ${shouldSaveResource('catalog', productCatalog)}
              ${shouldSaveResource('attribute', productAttribute)}
              ${shouldSaveResource('price', productPrice)}
              ${shouldSaveResource('text', productText)}
              ${shouldSaveResource('supplier', productSupplier)}
              ${shouldSaveResource('media', productMedia)}
          }
        }) {
          id
          lists {
              product {
                  refid
              }
          }
        }
      }
      `
}

export const getSaveStockMutationQuery = (stock: ProductStock[] | undefined, productId: string) => `
  mutation {
    saveStock(input: {
     ${stock?.[0]?.id ? `id:"${stock[0].id}"` : ''}
      type: "default"
      productid: "${productId}"
      stocklevel: ${stock?.[0]['stock.stocklevel'] ?? ''}
      dateback: "${stock?.[0]['stock.dateback'] ?? ''}"
    }) {
      id
    }
  }`

export const getSaveRulesMutationQuery = (sku: string, ruleConfig: string, id?: string) => {
  const idStr = id ? `id: "${id}"` : ''

  return `
    mutation {
      saveRule(input: {
        ${idStr}
        type: "catalog"
        label: "${sku}"
        provider: "Custompriceprovider"
        config: "[${ruleConfig}]"
      }) {
        id
      }
    }`
}

export const deleteRuleMutationQuery = (id: string) => `
  mutation {
    deleteRule(id: "${id}")
  }
`
export const getSaveCustomerMutationQuery = (data: Partial<Customer>) => `
  mutation {
    saveCustomer(input: {
      id: "${data.id}"
      lists: {
        product: [${data?.product ? data.product.map((product) => `{
              domain: "product"
              refid: "${product.id}"
              type: "favorite"
        }`) : ''}]
      }
    }) {
      id
      lists {
        product(listtype: "favorite") {
          refid
        }
      }
    }
  }`

export const getFetchResourceQuery = (params: string, resource: ResourceName | null) => {
  switch (resource) {
    case 'product':
      return `
  query {
    searchResource:searchProducts(${params}) {
        id
        ctime
        type
        code
        label
        siteid
        status
        property {
            id
            type
            value
        }
        lists {

            product(listtype: "default") {
                item {
                    id
                    code
                    status
                    type
                    label
                    property {
                      id
                      type
                      value
                  }
                    lists {
                        attribute {
                            type
                            id
                            item {
                                code
                                id
                                label
                                type
                            }
                        }
                    }
                }
            }
            media {
                id
                position
                item {
                    id
                    label
                    preview
                    url
                    type
                }
            }
            catalog {
                id
                position
                item {
                    code
                    id
                    parentid
                    config
                    status
                    label
                    level
                    url
                }
            }
            attribute {
                id
                type
                item {
                    code
                    id
                    status
                    label
                    type
                }
            }

        price {
            id
            item {
                id
                costs
                currencyid
                label
                quantity
                rebate
                status
                taxrate
                value
                type
            }
    }
      text {
        id
        item {
          id
          type
          label
          content
          languageid
        }
    }
        }
    }
}`

    case 'catalog':
    {
      const isAttributeIncluded = params.includes('include:') ? params.split('include:')[1].includes('attribute') : ''
      const attirbuteQuery = isAttributeIncluded ? `
      lists {
        attribute {
          id
          type
          item {
            code
            id
            label
            type
            property(type: "scale-unit") {
              id
              type
              languageid
              value
            }
          }
        }
      }` : ''

      return `
  query {
    searchResource:searchCatalogs(${params}) {
      items {
        id
        code
        label
        level
        parentid
        config
        ${attirbuteQuery}
      }
    }
  }
  `
    }

    case 'attribute':
    {
      const isAttributeIncluded = params.includes('include:') ? params.split('include:')[1].includes('attribute') : ''
      const attributeQuery = isAttributeIncluded ? `
        lists{
          attribute{
                    id
                    type
                    item{
                        code
                        id
                        label
                        type
                    }
                }
        }` : ''
      return `
    query {
      searchResource:searchAttributes(${params})
      {
        items {
          id
          code
          type
          label
          property(type: "scale-unit") {
            id
            type
            languageid
            value
          }
          ${attributeQuery}
        }
      }
    }
    `
    }

    case 'supplier':
    {
      return `
    query {
      searchResource:searchSuppliers(${params}) {
        items {
          id
          code
          label
          siteid
        }
      }
    }
    `
    }

    case 'stock':
    {
      return `
      query {
        searchResource:searchStocks(${params}) {
          items {
            id
            type
            productid
            stocklevel
            timeframe
            dateback
          }
        }
      }
      `
    }

    case 'rule':
    {
      return `
        query {
          searchResource:searchRules(${params}) {
            items {
              id
              siteid
              type
              label
              provider
              config
              status
              editor
            }
          }
        }
      `
    }

    default:
      return ''
  }
}
