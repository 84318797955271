import { ComponentType, PropsWithoutRef, forwardRef, useEffect } from 'react'

import { useAuth } from './useAuth'

const withRequireAuth = <T extends JSX.IntrinsicAttributes>(WrappedComponent: ComponentType<T>) => {
  const ForwardedRefComponent = forwardRef<unknown, T>((props: T, ref) => (
    <WrappedComponent {...props} ref={ref} />
  ))

  return (props: PropsWithoutRef<T>) => {
    const auth = useAuth()

    useEffect(() => {
      const checkUserData = () => {
        const user = localStorage.getItem('user')
        if (!user) {
          auth.clearUserData()
        }
      }

      window.addEventListener('storage', checkUserData)

      return () => {
        window.removeEventListener('storage', checkUserData)
      }
    }, [])

    if (typeof window === 'undefined') {
      return null
    }

    if ((auth == null || auth.user === null) && !localStorage.getItem('user')) {
      return null
    }

    return (
      <ForwardedRefComponent {...props} />
    )
  }
}

export default withRequireAuth
