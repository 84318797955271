import Image from 'next/image'
import { FC } from 'react'

import { imageLoader } from '../../utils/images'

import styles from './Avatar.module.less'

interface AvatarProps {
  imageSrc?: string
  altName: string
  size: 'xl' | 'lg' | 'md' | 'sm'
  shape?: 'circle' | 'square'
  publicFolderImageSrc?: string
}

const Avatar: FC<AvatarProps> = ({ imageSrc = '', altName = '', size, publicFolderImageSrc, shape = 'circle' }) => (
  <div className={`${styles[`image-container-height-${size}`]} ${styles[`image-shape-${shape}`]}`}>
    {publicFolderImageSrc
      ? (
        <img
          src={publicFolderImageSrc}
          alt={altName}
        />
      )
      : (
        <Image
          loader={imageLoader}
          src={imageLoader(imageSrc)}
          alt={altName}
          layout="fill"
        />
      )}
  </div>
)

export default Avatar
