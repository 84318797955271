import { FC } from 'react'
import { Drawer } from 'rsuite'
import ArrowBackIcon from '@rsuite/icons/ArowBack'

import styles from '../../../../styles/Header.module.less'

interface MobileDrawerHeaderProps {
  additionalHeaderClassNames?: string
  handleBackNavigation?: () => void
}

const MobileDrawerHeader: FC<MobileDrawerHeaderProps> = ({
  children,
  handleBackNavigation,
  additionalHeaderClassNames = '',
}) => (
  <Drawer.Header className={`${styles['mobile-drawer-header']} ${additionalHeaderClassNames}`}>
    {handleBackNavigation && (
      <ArrowBackIcon
        role="button"
        className={styles['back-icon']}
        onClick={handleBackNavigation}
      />
    )}
    <Drawer.Title>
      {children}
    </Drawer.Title>
  </Drawer.Header>
)

export default MobileDrawerHeader
