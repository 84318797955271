import { IconProps } from '@rsuite/icons/lib/Icon'
import { FC } from 'react'

const OrderTimeIcon: FC<IconProps> = ({ className }) => (
  <svg width={22} height={23} className={className} viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.1082 22H2C1.44772 22 1 21.5523 1 21V2C1 1.44772 1.44772 1 2 1H17.2941C17.8464 1 18.2941 1.44772 18.2941 2V11.3065" stroke="#575757" strokeWidth="1.2" strokeLinecap="round" />
    <circle cx="15.5898" cy="16.6792" r="5.75" stroke="#575757" />
    <path d="M18.0446 14.7419C18.1205 14.6616 18.2471 14.658 18.3274 14.7338C18.3987 14.8012 18.4095 14.9087 18.3578 14.9882L18.3354 15.0165L14.9354 18.6165C14.8641 18.692 14.749 18.6991 14.6694 18.6388L14.6414 18.613L12.8414 16.613C12.7675 16.5309 12.7741 16.4044 12.8562 16.3305C12.9292 16.2649 13.0373 16.2628 13.1123 16.3208L13.1387 16.3454L14.7932 18.184L18.0446 14.7419Z" fill="#575757" stroke="#575757" strokeWidth="0.6" />
  </svg>

)

export default OrderTimeIcon
