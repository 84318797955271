import dayjs from 'dayjs'
import { Translate } from 'next-translate'
import isArray from 'lodash/isArray'

import {
  CountryData,
  Currency,
  DEFAULT_CURRENCY,
  PRODUCT_ORDER_PRICE_LIMIT,
  TaxCountryId,
  VATs,
} from '../utils/constants'
import { getLargePreview, getMediumPreview, imageLoader } from '../utils/images'
import {
  Catalog,
  FeatureType,
  MediaType,
  PartialProduct,
  Product,
  ProductAttribute,
  ProductQuantity,
} from '../utils/types/Product'
import { capitalizeFirstLetter, normalizeString } from '../utils/util'
import { getProductPrice, getProductPriceObject, isDiscount, isOldDiscount } from './resources/price'
import { getPerBoxItems, getPerCartonItems, getProductSalesCount, getSalesUnit } from './salesUnit'
import { getSupplierDeliveryDetails } from './service'
import { formatTimeInterval } from './time'
import { getProductImageAltText, getProductName, getTextContentByType } from './text'
import { getVariantType } from './variant'
import { formatGenericIntegerNumber, formatPriceNumber } from '../utils/math'
import { CountryAndLocale, Locale } from '../external'
import { findByType, getValue, PropertyType } from './property'
import { convertFeatureNumericUnit, SalesUnitType } from './resources/attribute'
import { IAggregateIdentifierObject, ICartLinks } from '../utils/types/aimeosApi'
import { ProductProperty } from '../utils/types/Resource'
import { getBasePathWithLocale } from '../utils/urls'
import { getCountryAndLocaleStrings } from '../utils/locales'

interface GetProductsParams {
  products: Product[]
  countryAndLocaleString: CountryAndLocale
  tProducts: Translate
  tUrl: Translate
}

export const isProductV2 = (product: Product) => product.catalog.some((category) => category['catalog.code'].includes('c_'))

export const getBackInStockDate = (product?: Pick<Product, 'stock' | 'stock.dateback'>): string | null => {
  const stockBackDate = (product?.['stock.dateback'] ?? product?.stock?.[0]?.['stock.dateback']) || null
  return (
    dayjs(stockBackDate) > dayjs()
      ? stockBackDate
      : null)
}

export const getProductManualStockLimit = (product: Product) => product['product/property']?.find(
  (prop) => prop['product.property.type'] === 'manual-stock-limit',
)?.['product.property.value']

export const getProductMedia = (product?: Product): MediaType[] => product?.media?.map(
  (file, index) => ({
    original: imageLoader(getLargePreview(file)),
    thumbnail: imageLoader(getMediumPreview(file)),
    originalHeight: 500,
    thumbnailHeight: 100,
    originalAlt: getProductImageAltText(product, index + 1),
    thumbnailAlt: `${getProductImageAltText(product, index + 1)}, gallery thumbnail`,
  }),
) || []

export const getCatalogIdToBeUsedForSearch = (
  catalogIds: string[],
) => catalogIds?.[3] || catalogIds?.[2] || catalogIds?.[1] || catalogIds?.[0]

export const getProductMoq = (product?: Product) => getProductPriceObject(product)?.['price.quantity'] ?? 1

export const getProductMov = (product: Product) => Number(getProductMoq(product))
  * Number(getProductPrice(product))

export const getAttributeType = (
  attributeType: string,
  product?: Product,
) => product?.attribute?.filter(
  (ele) => ele['attribute.type'] === attributeType,
)

export const getAttributeDescription = (attribute?: ProductAttribute) => (
  attribute ? getTextContentByType(attribute, 'long') : '')

export const getProductFeatures = (product: Product) => getAttributeType('feature', product)

export const getProductCertifications = (product: Product) => getAttributeType('standard-certification', product)

export const getProductColors = (product: Product) => getAttributeType('color', product)

export const getProductIndustries = (product: Product) => getAttributeType('industry', product)

export const getProductUseCases = (product?: Product) => getAttributeType('use-case', product)

export const getProductBaseStandards = (product: Product) => getAttributeType('standards-base', product)

export const getProductStandardVersions = (product: Product) => getAttributeType('standards-version', product)

export const getProductStandardTests = (product: Product) => getAttributeType('standards-test', product)

export const getProductFeatureTypes = (product: Product) => getAttributeType('feature-type', product)

export const getProductLabels = (product: Product) => getAttributeType('label', product)

export const getProductFeatureTypeCodes = (product: Product) => {
  const featureTypes = getProductFeatureTypes(product)
  return featureTypes?.map((el) => el['attribute.code'])
}

export const getNumericFeatureTypes = (product: Product) => {
  const featureTypeCodes = getProductFeatureTypeCodes(product)
  const featureProperties = product['product/property']
    .filter((property) => featureTypeCodes?.some(
      (featureType) => property['product.property.type'] === featureType,
    ))
  return featureProperties
}

export const getDescriptiveFeatureTypes = (product: Product) => {
  const featureTypeCodes = getProductFeatureTypeCodes(product)
  const descriptiveFeatureTypes = product.attribute
    .filter((attribute) => featureTypeCodes?.some(
      (code) => attribute['product.lists.type'] === code,
    ))
  return descriptiveFeatureTypes
}

export const getFeatureNumericUnit = (product: Product, featureTypeCode?: string): string => {
  const featureTypeAttributes = getProductFeatureTypes(product)
  const numericFeatureTypeUnit = featureTypeAttributes?.find((type) => type['attribute.code'] === featureTypeCode)
  const attributeValueArray = numericFeatureTypeUnit?.attribute?.[0]?.['attribute.label'].trim().split(' ')

  // If there's a unit, it's always the last item. If not, it will be the number format (#,##)
  const unit = attributeValueArray?.pop() || ''
  return unit?.includes('#') ? '' : unit
}

export const getFeatureTypeObject = (
  code: string,
  label: string,
  value: string | string[],
  text: string | string[],
  type: 'descriptive' | 'numeric',
) => ({
  code,
  label,
  value,
  text,
  type,
} as FeatureType)

export const getFeatureTypeColorObject = (product: Product): FeatureType => {
  const colors = getProductColors(product)
  const code = 'color'
  const label = 'Color'
  const value = colors?.map((color) => color['attribute.label']) || []
  const type = 'descriptive'
  return getFeatureTypeObject(code, label, value, '', type)
}

const getDescriptiveValue = (
  hasMultipleValues: boolean,
  descriptiveFeatureType: ProductAttribute[],
) => (hasMultipleValues
  ? descriptiveFeatureType.map((featureType) => featureType['attribute.label'])
  : descriptiveFeatureType[0]?.['attribute.label'] || '')

const getNumericValue = (
  numericFeatureTypes: ProductProperty[],
  code: string,
  featureType: ProductAttribute,
  product: Product,
) => {
  const numericFeatureType = findByType(numericFeatureTypes, code)
  const scaleUnit = findByType(featureType, PropertyType.scaleUnit)
  const baseUnit = getFeatureNumericUnit(product, code)
  const numericValueBase = convertFeatureNumericUnit(numericFeatureType?.['product.property.value'], baseUnit)
  const scaleUnitValue = scaleUnit ? getValue(scaleUnit) : ''
  const numericValueWithScaleUnit = numericFeatureType
    ? `${getValue(numericFeatureType)} ${scaleUnitValue}`
    : ''

  // Use scale unit if available, otherwise scale based on base unit
  return scaleUnit ? numericValueWithScaleUnit : numericValueBase
}

export const getFeatureTypesArray = (product: Product): FeatureType[] => {
  // 1. Get feature types and codes
  const featureTypeAttributes = getProductFeatureTypes(product)
  // 2. Get numeric and descriptive feature type arrays
  // All attribute feature-type values are descriptive, all product/property values are numeric
  const descriptiveFeatureTypes = getDescriptiveFeatureTypes(product)
  const numericFeatureTypes = getNumericFeatureTypes(product)
  // 3. Consolidate all feature type values to a single array
  const featureTypesArray = featureTypeAttributes?.map((featureType: ProductAttribute) => {
    const code = featureType['attribute.code']

    // Handle descriptive feature type
    const isDescriptive = descriptiveFeatureTypes.some((type) => type['product.lists.type'] === code) || false
    const descriptiveFeatureType = descriptiveFeatureTypes.filter((type) => type['product.lists.type'] === code) || []
    const hasMultipleValues = descriptiveFeatureType.length > 1

    const descriptiveText = hasMultipleValues
      ? descriptiveFeatureType.map((ft) => getAttributeDescription(ft))
        .filter((text) => text.length > 0)
      : getAttributeDescription(featureType)

    // Final values
    const label = featureType?.['attribute.label'] || ''
    const value = isDescriptive
      ? getDescriptiveValue(hasMultipleValues, descriptiveFeatureType)
      : getNumericValue(numericFeatureTypes, code, featureType, product)
    const text = descriptiveText || ''
    const type = isDescriptive ? 'descriptive' : 'numeric'

    return getFeatureTypeObject(code, label, value, text, type)
  })

  return featureTypesArray || []
}

export const getFeatureTypeValueString = (featureType?: FeatureType): string => {
  let value = ''

  if (isArray(featureType?.value)) {
    featureType?.value.forEach((featureTypeValue, valIndex) => {
      value = valIndex === 0
        ? featureTypeValue
        : `${value}, ${featureTypeValue}`
    })
  } else {
    value = featureType?.value || ''
  }

  return value
}

export const getProductCurrency = (product?: Product, localeId?: Locale | null) => {
  const productPrice = getProductPriceObject(product)
  const fallBackCurrency = CountryData.find((country) => country.locale === localeId)?.currency || 'EUR'
  return productPrice?.['price.currencyid'] ? Currency[productPrice?.['price.currencyid']] : Currency[fallBackCurrency]
}

export const getCurrencyFromSelectedCountry = (countryLocale: Locale | null) => {
  const countryInfo = CountryData.find((ele) => ele.locale === countryLocale)?.currency ?? 'EUR'
  return Currency[countryInfo]
}

export const isProductSpecificFreeDelivery = (product: Product | undefined): boolean => (
  product?.['product.free-shipping'] === true
)

export const isFreeDelivery = (
  product: Product,
  countryLocale: Locale | null,
  totalPrice?: number,
) => {
  const {
    deliveryCost,
    freeShippingLimit,
  } = getSupplierDeliveryDetails(product.supplier[0], countryLocale)
  const mov = getProductMov(product)

  return (
    deliveryCost === 0
    || isProductSpecificFreeDelivery(product)
    || !!(freeShippingLimit && mov >= freeShippingLimit)
    || !!(totalPrice && totalPrice >= freeShippingLimit)
    || freeShippingLimit === 0
  )
}
export const getProductDescription = (product: Product) => (
  getTextContentByType(product, 'short'))

export const getProductSizeUnits = (product: Product) => {
  const height = product['product/property'].find(
    (property) => property['product.property.type'] === 'unit-height',
  )?.['product.property.value']
  const width = product['product/property'].find(
    (property) => property['product.property.type'] === 'unit-width',
  )?.['product.property.value']
  const length = product['product/property'].find(
    (property) => property['product.property.type'] === 'unit-length',
  )?.['product.property.value']
  return { height, width, length }
}

export const getProductTotalPieceCountText = (totalPieceCount: number, unit: 'piece' | 'pair', t: Translate) => {
  switch (totalPieceCount) {
    case 0:
      return t(`products:${unit}_0`)
    case 1:
      return t(`products:${unit}_one`)
    default:
      return t(`products:${unit}_other`)
  }
}

export const getHasBulkDiscount = (product: Product | PartialProduct) => product.price.some((price) => price['price.label'].includes('pallet'))

export const getPalletSize = (product: Product | PartialProduct) => {
  // First, check new way (product property)
  const palletSize = product['product/property']?.find((p) => p['product.property.type'] === 'per-pallet')
  if (palletSize) {
    return Number(palletSize['product.property.value'])
  }
  // If not found, check old way (price label or, less preferably, price quantity)
  if (getHasBulkDiscount(product)) {
    const palletPriceObjects = product.price.filter((price) => price['price.label'].includes('pallet'))
    let [palletPriceObject, ...restPalletPriceObjects] = palletPriceObjects
    if (palletPriceObject['price.label'].includes('custom') && !palletPriceObject['price.label'].split('|')[1]) {
      if (!restPalletPriceObjects) {
        // can't deduce pallet price - this shouldn't happen
        return 0
      }
      [palletPriceObject, ...restPalletPriceObjects] = restPalletPriceObjects
    }
    if (palletPriceObject) {
      const priceLabel = palletPriceObject['price.label']
      if (priceLabel.split('|')[1]) {
        return Number(palletPriceObject['price.label'].split('|')[1])
      }
      const quantity = Number(palletPriceObject['price.quantity'])
      if (isDiscount(palletPriceObject) || isOldDiscount(palletPriceObject)) {
        return quantity
      }
    }
  }
  return 0
}

export const getPalletTotalUnits = (product: Product) => {
  const salesUnit: SalesUnitType = getSalesUnit(product)
  const packageSize = getPerBoxItems(product)
  const cartonSize = getPerCartonItems(product)
  const palletSize = getPalletSize(product)
  let palletAmount = ''

  switch (salesUnit) {
    case 'piece':
    case 'pair':
      palletAmount = formatGenericIntegerNumber(palletSize)
      break
    case 'package':
      palletAmount = formatGenericIntegerNumber(palletSize * packageSize)
      break
    default: // carton
      palletAmount = formatGenericIntegerNumber(palletSize * cartonSize * packageSize)
  }

  const palletAmountCheck = Number(palletAmount?.replace(/\s/g, ''))

  return palletAmountCheck ? palletAmount : ''
}

export const getPalletUnitsText = (product: Product, t: Translate) => {
  const salesUnit: SalesUnitType = getSalesUnit(product)
  const palletSize = getPalletSize(product)

  switch (salesUnit) {
    case 'piece':
      return palletSize > 1 ? t('piece_other') : t('piece_one')
    case 'package':
      return palletSize > 1 ? t('package_other') : t('package_one')
    case 'pair':
      return palletSize > 1 ? t('pair_other') : t('pair_one')
    default: // carton
      return palletSize > 1 ? t('carton_other') : t('carton_one')
  }
}

export const getProductPath = (
  product: Product,
  t: Translate | ((s: string) => string) = (s: string) => s,
  localizedName?: string,
) => {
  const productName = localizedName || getProductName(product)
  return normalizeString(`${productName}${getProductSalesCount(product, t)}`).toLowerCase()
}

export const getPathName = (product: Product | undefined, t: Translate, localized?: string) => {
  if (!product) return ''
  const productName = getProductPath(product, t, localized)
  return `/${t('url:product')}/${product['product.code'].toLowerCase()}+${productName}`
}

export const isNewProduct = (product: Product) => dayjs(product['product.ctime']).isAfter(
  dayjs().subtract(Number(process.env.NEXT_PUBLIC_NEW_PRODUCT_LABEL || 14), 'day'),
)

export const getMinProductValue = (products: Product[]) => {
  const values = products
    .map((product) => getProductMov(product))
    .filter((val) => !Number.isNaN(val))
  if (values.length > 0) return Math.min(...values)
  return 0
}

export const getDeliveryEstimation = (
  product: Pick<Product, 'supplier' | 'stock' | 'stock.dateback'>,
  countryLocale: Locale | null,
  lang: string,
): string => {
  const { deliveryTime } = getSupplierDeliveryDetails(product.supplier[0], countryLocale)
  const outOfStock = getBackInStockDate(product)
  return `${formatTimeInterval(deliveryTime.toString(), outOfStock ?? new Date().toISOString(), undefined, lang)}`
}

export const getProductEstimatedDeliveryText = (
  product: Pick<Product, 'supplier' | 'stock' | 'stock.dateback'>,
  countryLocale: Locale | null,
  t: Translate,
  lang: string,
) => {
  const dateText = getDeliveryEstimation(product, countryLocale, lang)

  return (
    `${t('products:Estimated delivery')} ${dateText}`
  )
}

export const getTotalProductQuantity = (productQuantity: ProductQuantity[]) => productQuantity
  .map((prod) => prod.quantity)
  .reduce((total, current) => Number(total) + Number(current), 0)

export const getTaxRate = (country: Locale | null) => {
  const countryId = CountryData.find((ele) => ele.locale === country)?.locale as TaxCountryId
  return VATs[countryId ?? 'fi']
}

export const getProductPriceWithTax = (product: Product, country: Locale | null) => {
  const productPriceObject = getProductPriceObject(product)
  const originalPrice = Number(productPriceObject?.['price.value']) ?? 1
  const taxRate = getTaxRate(country)
  const tax = originalPrice * (taxRate / 100)

  return originalPrice + tax
}

export const getProductLabel = (
  item: ProductQuantity,
  product: Product,
  t: Translate | ((s: string) => string) = (s: string) => s,
) => (
  getVariantType(product.product) === 'color'
    ? t(`color:${capitalizeFirstLetter(item.label)}`)
    : item.label
)

export const isOrderPriceLimitReached = (totalPrice: number, product: Product) => {
  const productPriceObj = getProductPriceObject(product)
  const currencyId = productPriceObj?.['price.currencyid'] || DEFAULT_CURRENCY

  return totalPrice >= PRODUCT_ORDER_PRICE_LIMIT[currencyId]
}

export const createProductCode = (
  productCode: string,
  variants: Product[],
  clashingSKUs: Set<string>,
) => {
  // Should be over 1 to ignore a case with a default variant
  const hasVariants = variants.length > 1
  if (!hasVariants) return productCode

  let postfix = '-VAR'
  const variantSKUs = variants.map((variant) => variant['product.code'])
  const allSKUsList = [...clashingSKUs, ...variantSKUs]
  const commonPrefix: string[] = []
  const postfixRegex = new RegExp(postfix, 'g')

  // Small util function to get the number of times the postfix should repeat based on the largest
  // amount of matches of the checked SKU and the postfix that can be found in other product and
  // the product variant SKUs
  const getMaxPostfixRepeatNumber = (skuToCheck: string) => Math.max(
    ...allSKUsList
      .filter((sku) => sku.includes(skuToCheck))
      .map((res) => res.match(postfixRegex)?.length || 0),
  )

  // Get common prefix: [SKU-001-L, SKU-001-M] --> SKU-001
  variantSKUs[0]
    .split('')
    .every((char, idx) => {
      const matches = variantSKUs.every((sku) => sku.charAt(idx) === char)
      if (matches) commonPrefix.push(char)
      return matches
    })
  const commonSKU = commonPrefix.join('').replace(/(-$)/, '')

  if (commonSKU) {
    // If no SKU clash was found, return the common SKU value
    if (!clashingSKUs.has(commonSKU) && !variantSKUs.includes(commonSKU)) return commonSKU

    // handle Edge case: Otherwise repeat the postfix with the calculated amount (and add +1 to
    // add the extra postfix to go above the repeat number)
    const repeatLength = getMaxPostfixRepeatNumber(commonSKU)
    return `${commonSKU}${postfix.repeat(repeatLength + 1)}`
  }

  let baseSKU = variantSKUs[0] // should be the first SKU, otherwise the first SKU with a value
  if (!baseSKU) baseSKU = variantSKUs.find((sku) => Boolean(sku)) || ''

  // The length should be 75% of the SKU length, or at least one char long
  const subStringLength = Math.round((baseSKU.length * 0.75)) || 1
  const splitSubString = baseSKU.slice(0, subStringLength) || 'SKU' // 'SKU' fallback for edge case of an empty string

  // Handle edge case: in the case where there are other variant SKUs that end with the -VAR
  // postfix, e.g. [SKU-VAR, 1234, SKU-VAR-VAR], we will handle this by repeating
  // the postfix to avoid SKU clashes - e.g. SKU-VAR-VAR-VAR
  if (variantSKUs.some((sku) => sku.endsWith(postfix))) {
    let postfixRepeatCount = 0

    variantSKUs.forEach((sku) => {
      // The number of postfix repeats will increase if more postfix endings will be matched
      const numberOfMatches = sku.match(postfixRegex)?.length || 0
      postfixRepeatCount += numberOfMatches
    })

    // If there was only one increment, increment it by 1 again to get the correct '-VAR-VAR' result
    if (postfixRepeatCount === 1) postfixRepeatCount += 1

    postfix = postfix.repeat(postfixRepeatCount)
  }

  let formattedProductCode = `${splitSubString}${postfix}`

  // Handle edge case: in the case where the newly formatted code clash with other products SKU,
  // just like with the common SKU, repeat the postfix again based on the calculated
  // amount of the longest repeating SKU
  if (clashingSKUs.has(formattedProductCode)) {
    const repeatLength = getMaxPostfixRepeatNumber(splitSubString)
    formattedProductCode = `${formattedProductCode}${postfix.repeat(repeatLength)}`
  }

  return formattedProductCode
}

export const getIsStockLimitReached = (product: Product, productQuantity: ProductQuantity[]) => {
  const manualStockLimit = getProductManualStockLimit(product)
  const totalQuantity = getTotalProductQuantity(productQuantity)
  return Number(totalQuantity) > Number(manualStockLimit) || !!(productQuantity.find(
    (prod) => prod.manualStockLimit && prod.manualStockLimit < Number(prod.quantity),
  ))
}

export const isOutOfStockByDate = (stockDate: string | null) => (
  stockDate !== null && dayjs(stockDate).valueOf() >= new Date().getTime()
)

export const isOutOfStock = (item: ProductQuantity) => (
  isOutOfStockByDate(item.stockDate)
)

export const getProductsCount = (
  productsAggregate: IAggregateIdentifierObject[],
  category: Partial<Catalog>,
): IAggregateIdentifierObject | undefined => productsAggregate.find(
  (aggregate) => aggregate.id.toString() === category.id,
)

export const filterNoProductCategories = (
  productsAggregate: IAggregateIdentifierObject[],
  categories?: Catalog[],
) => categories?.filter(
  (cat) => !!getProductsCount(productsAggregate, cat),
) ?? []

export const getProductAddToCartLink = (links: ICartLinks[]) => {
  const addToCartLinkObj = links[0]?.['basket/product']

  return typeof addToCartLinkObj === 'string' ? addToCartLinkObj : addToCartLinkObj?.href
}

export const getOrderedProductCategories = (
  product: Product,
) => (
  product?.catalog
    .filter((cat) => cat['catalog.code'].startsWith('c_'))
    .sort((a, b) => (a?.['catalog.level'] || 0) - (b?.['catalog.level'] || 0))
)

export const getProductSizes = (product: Product) => {
  const variants = product.product
  const isSizeVariant = getVariantType(variants) === 'size'

  if (!variants?.length || !isSizeVariant) return []

  const variantAttributes = variants?.flatMap((variant) => variant.attribute)
  const sizesArray = variantAttributes.map((item) => item['attribute.label'])

  return sizesArray
}

export const getProductVariantQuantities = (
  productQuantity: ProductQuantity[],
) => productQuantity
  .filter(({ quantity }) => !!quantity) // Filter out empty quantities
  .reduce<Record<string, number>>((prev, { variantId, quantity }) => ({
  ...prev,
  [variantId || '']: Number(quantity),
}), {})

export const getFormattedProductsResponseForOrttoApi = async (params: GetProductsParams) => {
  const {
    products,
    countryAndLocaleString,
    tProducts,
    tUrl,
  } = params

  const { country } = getCountryAndLocaleStrings(countryAndLocaleString)

  return products.map((product) => {
    const productSalesUnit = tProducts(getSalesUnit(product), { count: 1 })
    const currencySymbol = getProductCurrency(product, country)

    return ({
      sku: product['product.code'],
      name: getProductName(product),
      image: getProductMedia(product)[0]?.original || getProductMedia(product)[0]?.thumbnail || '',
      price: `${formatPriceNumber(getProductPrice(product))} ${currencySymbol} / ${productSalesUnit}`,
      productUrl: `${getBasePathWithLocale(countryAndLocaleString)}${getPathName(product, tUrl)}`,
      supplier: {
        supplierCode: product.supplier[0]['supplier.code'] || '',
        supplierName: product.supplier[0]['supplier.label'] || '',
      },
    })
  })
}

export const getCurrentVariant = (product: Product, variantId: string) => product?.product
  ?.find((variant: Product) => (
    // Previously the variant label was saved instead of variant attribute id,
    // so we need to match the label for old data
    variant.attribute
      .flatMap((attribute) => attribute.id)
      .includes(variantId)
              || variant.attribute
                .flatMap((attribute) => attribute['attribute.label'])
                .includes(variantId)
              || variant['product.label'].includes(variantId)
  ))
