import { FC } from 'react'
import { Highlight, HitsProps, useHits } from 'react-instantsearch'
import { useRouter } from 'next/router'
import { FlexboxGrid, List, Stack } from 'rsuite'
import useTranslation from 'next-translate/useTranslation'

import useSearchEvent from '../../services/analytics/useSearchEvent'
import useUrls from '../../services/useUrls'
import AnchorLink from '../AnchorLink'
import Img from '../Img'
import { getCountryAndLocaleStrings, getCurrencyCode } from '../../utils/locales'
import { normalizeString } from '../../utils/util'
import { SearchResult, TypesenseProduct } from '../../utils/types/search'
import { isDefined } from '../../utils/types/misc'

import styles from '../../styles/Search.module.less'

const ProductHits: FC<HitsProps<TypesenseProduct> & { closeModal: () => void }> = (props) => {
  const { closeModal, ...defaultProps } = props

  const { locale: countryAndLocale } = useRouter()
  const { locale, country } = getCountryAndLocaleStrings(countryAndLocale)
  const { urlT } = useUrls()
  const { t } = useTranslation('products')

  const {
    hits,
    results,
  } = useHits(defaultProps)

  useSearchEvent({
    searchTerm: results?.query || '',
    resultQuantity: results?.nbHits || 0,
    searchResults: results?.hits.map((hit): SearchResult => ({
      item_id: hit.sku,
      item_name: hit.texts.en.name,
      price: hit.price,
      currency: getCurrencyCode(country),
      item_brand: hit.texts.brand ?? `${hit.supplier} (fallback, supplier name)`,
      supplier: hit.supplier,
      item_category: hit.categories.one?.en,
      item_category2: hit.categories.two?.en,
      item_category3: hit.categories.three?.en,
    })) || [],
    isLoading: !isDefined(results),
  })

  const url = (sku: string, name: string) => urlT(`/product/${sku.toLowerCase()}+${normalizeString(name).toLowerCase()}`)

  return (
    <>
      <FlexboxGrid align="middle" justify="space-between">
        <FlexboxGrid.Item>
          <h5>{t('Products')}</h5>
        </FlexboxGrid.Item>
        { results && (
          <FlexboxGrid.Item>
            {t('common:Showing {{ hits }} of {{ total }} results', { hits: results.hits.length, total: results.nbHits })}
          </FlexboxGrid.Item>
        ) }
      </FlexboxGrid>
      <List size="sm" className={styles['hits-list']}>
        { hits.map((hit) => (
          <List.Item key={hit.id}>
            <AnchorLink
              onClick={(event) => {
                if (!event.ctrlKey && !event.metaKey) {
                  closeModal()
                }
              }}
              href={url(hit.sku, hit.texts[locale ?? 'en'].name)}
            >
              <FlexboxGrid className={styles['hit-row']} align="middle" justify="start">
                <FlexboxGrid.Item className={styles['image-container']}>
                  <Img src={hit.image} layout="fill" />
                </FlexboxGrid.Item>
                <FlexboxGrid.Item className={styles['text-box']}>
                  <Stack direction="column" justifyContent="space-between">
                    <Stack.Item>
                      <h6 className={styles.name}>
                        <Highlight
                          hit={hit}
                          attribute={`texts.${locale ?? 'en'}.name`}
                        />
                      </h6>
                    </Stack.Item>
                    <Stack.Item>
                      <Highlight className={styles.sku} hit={hit} attribute="sku" />
                    </Stack.Item>
                  </Stack>
                </FlexboxGrid.Item>
              </FlexboxGrid>
            </AnchorLink>
          </List.Item>
        )) }
      </List>
    </>
  )
}

export default ProductHits
