import React, { FC } from 'react'
import { IconProps } from '@rsuite/icons/lib/Icon'

import styles from '../../styles/DroppeLogo.module.less'

interface DroppeLogoProps extends IconProps{
  alignment?: 'left' | 'center' | 'right'
  size?: 'sm' | 'sd' | 'lm' | 'md' | 'lg'
  color?: 'white' | 'teal-darker'
  className?: string
}

const DroppeLogo: FC<DroppeLogoProps> = (props) => {
  const { alignment, size, color, className } = props

  return (
    <svg
      id="svg"
      data-testid="droppe-logo"
      className={`
        ${color ? styles[color] : ''} 
        ${styles['droppe-logo']} 
        ${alignment ? styles[`align-${alignment || 'left'}`] : ''} 
        ${styles[`size-${size || 'md'}`]}
        ${className}
        `}
      version="1.1"
      width="400"
      height="155"
      viewBox="0 0 565 155"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M96.6134 28.462C92.181 19.4622 85.3411 12.3748 76.2513 7.40238C67.2739 2.49746 55.7541 0 42.0293 0H27.0221V23.5796H0V119.361H42.0068C55.7316 119.361 67.2514 116.863 76.2513 111.958C85.3411 106.963 92.181 99.8759 96.6134 90.876C100.956 82.0337 103.161 71.5263 103.161 59.669C103.161 47.8117 100.956 37.3269 96.6134 28.462ZM72.6288 79.5362C70.5139 84.6436 66.9814 88.6261 62.099 91.3485C57.0591 94.161 49.9717 95.5785 41.0168 95.5785H26.9996V23.6021H41.0168C49.9717 23.6021 57.0366 25.0646 62.0765 27.922C66.9589 30.6895 70.5139 34.7169 72.6288 39.8919C74.8338 45.3368 75.9588 51.9967 75.9588 59.669C75.9588 67.3414 74.8338 74.1363 72.6288 79.5362Z" fill="#023512" />
      <path d="M147.148 36.1552C143.998 38.0902 141.185 40.4527 138.778 43.1751L137.473 33.0503H113.398V119.359H140.083V79.7595C140.083 74.1571 140.96 69.8147 142.67 66.8222C144.335 63.9423 146.585 61.9623 149.533 60.8148C152.728 59.5548 156.463 58.9024 160.603 58.9024H169.423V31.0703H166.453C159.118 31.0703 152.638 32.7803 147.148 36.1327V36.1552Z" fill="#023512" />
      <path d="M238.497 36.8312C231.86 33.0287 224.345 31.0938 216.2 31.0938C208.055 31.0938 200.383 33.0287 193.746 36.8312C187.108 40.6336 181.753 45.9885 177.816 52.7384C173.901 59.4883 171.898 67.3857 171.898 76.183C171.898 84.9804 173.856 92.8777 177.726 99.6276C181.596 106.378 186.928 111.732 193.566 115.557C200.203 119.36 207.763 121.295 216.043 121.295C224.323 121.295 231.725 119.36 238.407 115.557C245.112 111.755 250.467 106.378 254.337 99.6276C258.207 92.8777 260.164 85.0029 260.164 76.183C260.164 67.3632 258.207 59.4883 254.337 52.7609C250.467 46.011 245.135 40.6561 238.497 36.8312ZM228.013 92.1803C224.57 95.6677 220.655 97.3777 216.043 97.3777C211.43 97.3777 207.56 95.6902 204.163 92.2028C200.81 88.7378 199.1 83.3604 199.1 76.2055C199.1 69.0506 200.81 63.6732 204.163 60.2083C207.56 56.7208 211.498 55.0334 216.2 55.0334C220.903 55.0334 224.57 56.7208 228.013 60.2308C231.41 63.6957 233.142 69.0731 233.142 76.2055C233.142 83.3379 231.41 88.7153 228.013 92.1803Z" fill="#023512" />
      <path d="M343.48 37.0562C337.09 33.0962 329.733 31.0938 321.655 31.0938C314.321 31.0938 308.178 32.6012 303.453 35.6162C300.866 37.2587 298.548 39.0586 296.501 41.0161L294.926 33.0287H271.031V154.999H297.716V113.127C299.718 114.86 301.968 116.367 304.421 117.582C309.348 120.057 315.131 121.295 321.633 121.295C329.71 121.295 337.045 119.315 343.435 115.422C349.802 111.552 354.887 106.153 358.6 99.4026C362.29 92.6753 364.157 84.9129 364.157 76.3405C364.157 67.7682 362.29 60.0058 358.6 53.2109C354.91 46.416 349.802 40.9711 343.458 37.0337L343.48 37.0562ZM331.533 91.4603C327.753 95.3302 323.118 97.1977 317.291 97.1977C311.463 97.1977 306.716 95.3077 303.003 91.3928C299.313 87.5003 297.423 82.3929 297.423 76.183C297.423 69.9731 299.313 64.8657 303.003 60.9733C306.716 57.0808 311.396 55.1684 317.291 55.1684C323.185 55.1684 327.73 57.0808 331.51 61.0408C335.268 64.9782 337.18 70.1306 337.18 76.318C337.18 82.5054 335.29 87.5903 331.533 91.4153V91.4603Z" fill="#023512" />
      <path d="M447.519 37.0562C441.129 33.0962 433.794 31.0938 425.694 31.0938C418.36 31.0938 412.217 32.6012 407.492 35.6162C404.905 37.2587 402.587 39.0586 400.54 41.0161L398.965 33.0287H375.07V154.999H401.755V113.127C403.757 114.86 406.007 116.367 408.46 117.582C413.387 120.057 419.192 121.295 425.672 121.295C433.749 121.295 441.084 119.315 447.474 115.422C453.842 111.552 458.926 106.153 462.639 99.4026C466.329 92.6753 468.196 84.9129 468.196 76.3405C468.196 67.7682 466.329 60.0058 462.661 53.2109C458.971 46.416 453.864 40.9711 447.519 37.0337V37.0562ZM435.549 91.4603C431.769 95.3302 427.112 97.1977 421.307 97.1977C415.502 97.1977 410.732 95.3077 407.02 91.3928C403.307 87.5003 401.44 82.3929 401.44 76.183C401.44 69.9731 403.33 64.8657 407.02 60.9733C410.732 57.0808 415.412 55.1684 421.307 55.1684C427.202 55.1684 431.747 57.0808 435.527 61.0408C439.284 64.9782 441.197 70.1306 441.197 76.318C441.197 82.5054 439.307 87.5903 435.572 91.4153L435.549 91.4603Z" fill="#023512" />
      <path d="M535.022 90.3784C533.852 92.6509 532.164 94.5408 529.937 95.9808C527.574 97.5333 524.559 98.3208 520.959 98.3208C516.054 98.3208 512.027 96.8133 508.607 93.7309C505.952 91.3459 504.22 88.1285 503.432 84.1685H563.889L564.091 81.4236C564.204 80.0061 564.294 78.6111 564.339 77.2611C564.384 75.9111 564.429 74.6062 564.429 73.4137C564.429 65.5838 562.584 58.3614 558.939 51.9265C555.294 45.4916 550.141 40.3392 543.639 36.6268C537.159 32.9143 529.644 31.0469 521.274 31.0469C512.364 31.0469 504.467 33.0043 497.83 36.8743C491.17 40.7667 485.882 46.2341 482.125 53.1865C478.39 60.0714 476.5 68.0138 476.5 76.8111C476.5 85.6085 478.435 93.3034 482.237 99.9858C486.062 106.713 491.417 112.001 498.122 115.713C504.805 119.403 512.544 121.27 521.117 121.27C528.002 121.27 534.279 119.988 539.791 117.423C545.304 114.881 550.006 111.303 553.786 106.848C557.544 102.393 560.266 97.3758 561.909 91.9309L562.381 90.3559H535.022V90.3784ZM509.597 57.6189C512.949 55.144 516.887 53.9065 521.274 53.9065C525.662 53.9065 529.599 55.234 532.592 57.8664C534.842 59.8464 536.282 62.2764 536.979 65.2913H504.22C505.367 61.9839 507.167 59.4189 509.597 57.6189Z" fill="#023512" />
    </svg>
  )
}

export default DroppeLogo
